import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const usePdfFileStore = create((set) => ({
    pdffiles: [],
    fetchPdfFiles: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/view-pdffiles/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ pdffiles: data });
    },
    createPdfFiles: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'product/create-pdffiles/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
    deletePdfFiles: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'product/delete-pdffiles/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    }
}));