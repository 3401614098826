import React from 'react'
import { Icon } from '@iconify/react';
import { useColorStore } from './../../../Store/ColorStore';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';

const NotificationList = (props) => {
    const { data } = props
    const { color } = useColorStore()
    return (
        <>
            {/* -----------------Assigned leads------------------------------------- */}
            {data.ntype === 'CHANGE_OWNER' &&
                <Link to={AdminPath.ASSIGNEDLEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="icon-park-outline:google-ads" className='noti_ico' style={{ backgroundColor: color.blue2 }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', fontWeight: 450 }}>New Leads Assigned</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------*** Service Expire of Client ***--------------------------------------- */}
            {data.ntype === 'SERVICE_EXPIRE' &&
                <Link to={AdminPath.LEADSSERVICES} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="jam:triangle-danger" className='noti_ico' style={{ backgroundColor: color.red }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Clients Service Expired.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------New Recommendation of Client--------------------------------------- */}
            {data.ntype === 'NEW_RECOMMENDATION' &&
                <Link to={AdminPath.RECOMMENDATION} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="typcn:message" className='noti_ico' style={{ backgroundColor: color.green }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Recommendations</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------General Notification--------------------------------------- */}
            {data.ntype === 'NEW_NOTIFICATION' &&
                <div className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="mi:notification" className='noti_ico' style={{ backgroundColor: "#000" }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Notification.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </div>
            }

            {/* -----------------------------------Email Notification--------------------------------------- */}
            {data.ntype === 'EMAIL_NOTIFICATION' &&
                <Link to={AdminPath.INBOX} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="iconoir:user" className='noti_ico' style={{ backgroundColor: color.blue }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Email Recieved.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------Email Notification--------------------------------------- */}
            {data.ntype === 'REPLY_EMAIL_NOTIFICATION' &&
                <Link to={AdminPath.INBOX} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="iconoir:user" className='noti_ico' style={{ backgroundColor: color.blue }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Email Reply Recieved.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------Payment Notification--------------------------------------- */}
            {data.ntype === 'INVOICE_NOTIFICATION' &&
                <Link to={AdminPath.ALLINVOICES} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="bx:rupee" className='noti_ico' style={{ backgroundColor: color.yellow }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Payment Recieved.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

        </>
    )
}

export default NotificationList