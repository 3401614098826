import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useSettingStore = create((set) => ({
    settingOptions: [],
    fetchSettingOption: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/fetch-setting-options/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ settingOptions: data })
    },
    createSettingOption: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-setting-options/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateSettingOption: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/update-setting-options/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
}));