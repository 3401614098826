// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyDDC2DZhBX6G-1hARlsNbIx376zgYENEtU",
    authDomain: "moneybugs-d0dae.firebaseapp.com",
    projectId: "moneybugs-d0dae",
    storageBucket: "moneybugs-d0dae.appspot.com",
    messagingSenderId: "983574521112",
    appId: "1:983574521112:web:d7971023a57b65d16dea6d"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };