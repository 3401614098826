import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLeadImportStore = create((set) => ({
    allLeads: [],exportLeads:[],
    allLeadsList: [],allLeadsOther:null,
    importExcelLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/import-excel-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
                'Content-Type': 'multipart/form-data',
            }
        });
        const data = await response.data;
        return data
    },
    importManualLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/import-manual-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchAllLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-all-enquiry/?'+payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
        
    },
    fetchExportLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-export-enquiry/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
        
    },
    fetchUnassignedLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-unassigned-enquiry/'+payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
        
    },
    setExportLeads: async (data) => {
        set({ exportLeads: data })
    },
    setAllLeads: async (data) => {
        set({ allLeads: data.data, allLeadsList: data.enquiry,allLeadsOther:{count:Number(data.count),currentPage:Number(data.currentPage)} })
    },
    updateAllLeadsList: async (alLeadData, payload) => {
        let items = [...alLeadData];
        for (let i = 0; i < payload.length; i++) {
            const e = payload[i];
            var foundIndex = await items.findIndex(x => x.id === e.id);
            items[foundIndex] = e
        }
        set({ allLeadsList: items });
    },

}));