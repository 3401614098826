import React, { useState, useEffect } from 'react'
import './Login.css'
import logo from '../Images/moneybugs.png'
import loginImg from '../Images/login-img.png'
import { useLoginStore } from '../Store/LoginStore'
import Swal from 'sweetalert2'
import moment from 'moment'

const Login = () => {
  const { loginUser } = useLoginStore()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ip, setIp] = useState('')

  const handleShowPassword = (e) => {
    if (e.target.checked) {
      setShowPassword(true)
    } else {
      setShowPassword(false)
    }
  }

  useEffect(() => {
    fetch('https://api.ipify.org?format=json').then(response => {
      return response.json();
    }).then((res) => {
      setIp(res.ip);
    })
  }, [])

  const [state, setstate] = useState({
    email: '',
    password: ''
  })

  const [error, setError] = useState({
    email: '',
    password: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (state.email.length === 0 && state.password.length === 0) {
      setIsLoading(false)
      setError({ ...error, email: 'Email Required...!', password: 'Password Required...!' })
    } else if (state.email.length === 0) {
      setIsLoading(false)
      setError({ ...error, email: 'Email Required...!', password: '' })
    }
    else if (state.password.length === 0) {
      setIsLoading(false)
      setError({ ...error, password: 'Password Required...!', email: '' })
    }
    else {
      setError({ ...error, password: '', email: '' })
      let data = {
        email: state.email, password: state.password, ip: ip
      }
      loginUser(data)
        .then(res => {
          // setIsLoading(false)
          localStorage.setItem('user', JSON.stringify(res))
          window.location.reload();
        })
        .catch(err => {
          setIsLoading(false)
          if (err.response.status === 400) {
            Swal.fire(
              'Error',
              err.response.data,
              'error'
            )
          } else {
            Swal.fire(
              'Error',
              'Something went wrong. Please try after sometime.',
              'error'
            )
          }
        })

    }
  }

  return (
    <>
      <div className="loginWrapper pt-2">
        <div className='pt-4'>
          <img src={logo} width={'180px'} height={'100px'} alt="" />
        </div>

        {/* <p className='text-secondary' style={{ fontWeight: 500, fontSize: '15px' }}>Money Bugs CRM</p> */}

        <div className="loginCard">
          <div className="myrow align_center">
            <div className="loginBlock" style={{ backgroundColor: "rgb(84, 159, 173)" }}>
              <img src={loginImg} width={'100%'} alt="" />
            </div>
            <div className="loginBlock">
              <h4 className='font_18'>Welcome Back !</h4>
              <p className="text_muted">Sign in to continue to Growth Box.</p>

              <form action="" onSubmit={handleSubmit} className='loginForm'>
                <div className='mb-3'>
                  <label className='loginLabel'>Email</label>
                  <input type="text" value={state.email} onChange={(e) => setstate({ ...state, email: e.target.value })} placeholder='Enter email' className='login_input' />
                  {error.email.length > 0 && <sub className='text-danger'>{error.email}</sub>}
                </div>
                <div className='mb-4'>
                  <label className='loginLabel'>Password</label>
                  <input type={showPassword ? 'text' : 'password'} value={state.password} onChange={(e) => setstate({ ...state, password: e.target.value })} placeholder='Enter password' className='login_input' />
                  {error.password.length > 0 && <sub className='text-danger'>{error.password}</sub>}
                </div>
                <div className='mb-4'>
                  <input type="checkbox" onChange={handleShowPassword} className="form-check-input" />
                  <label className='loginLabel2'>Show Password</label>
                </div>
                <button className="primaryButton w-100" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} type="submit">{isLoading ?
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                    Please wait...
                  </>

                  : "Sign In"}</button>
              </form>
            </div>
          </div>
        </div>

        <div className="mt-3 text-center text-secondary text_muted">
          © {moment().format('YYYY')} Moneybugs. All Right Reserved.
        </div>

      </div>
    </>
  )
}

export default Login