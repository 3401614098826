import React, { useState, useEffect } from 'react'
import './Sidebar.css'
import logo from '../../../Images/moneybugs.png'
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '@iconify/react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Ripples from 'react-ripples'
import { useColorStore } from '../../../Store/ColorStore';
import { Link, useLocation } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { fileUrl, mediaUrl, userType } from '../../../Export';
import AdminSidebar from './AdminSidebar';
import SalesSidebar from './SalesSidebar';
import FieldSidebar from './FieldSidebar';
import BackendSidebar from './BackendSidebar';
import OperationSidebar from './OperationSidebar';


const Sidebar = (props) => {
  const { user } = props
  let location = useLocation();
  const { color } = useColorStore()
  let token = JSON.parse(localStorage.getItem('user'))
  let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)

  // ------------------------------------------------------ Notification Work Is Here--------------------------------------------------------
  const [showDropDownNotification, setShowDropDownNotification] = useState(false)
  // document.onclick = function () {
  //   setShowDropDownNotification(false)
  // }
  // =============================handle Logout=========================
  const [isLoading, setIsLoading] = useState(false)
  const handleLogout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('supervisor')
    window.location.reload();
  }

  const handleChangeSupervisor = (d) => {
    localStorage.setItem("supervisor", JSON.stringify(d))
    window.location.reload();
  }

  if (user) {
    return (
      <>
        <div className="sidebar">
          <div className="logowrapper text-center">
            <img src={logo} alt="" style={{ height: '50px' }} />
          </div>

          <div style={{ height: '9vh' }}>
            <div className="reportingWrapper">
              <div className="d-flex align_center">
                <div><Avatar sx={{ bgcolor: "#fff", color: "#0C768A", fontSize: 13, fontWeight: 700, width: '30px', height: '30px' }}>{user.name[0]}</Avatar></div>
                <div className='px-3' style={{ textOverflow: "ellipsis", overflow: 'hidden', width: '100%', position: 'relative' }}>
                  <div className="reportingTitle mb-1 text-capitalize">
                    {user.user_type.toLowerCase()}
                  </div>
                  <p className="reportingName mb-0" style={{ textOverflow: "ellipsis", overflow: 'hidden', width: '100%', position: 'relative' }}>{supervisor ? 'Supervisor' : user.email}</p>
                </div>

              </div>
            </div>
          </div>

          {token.user_type === userType.admin && <AdminSidebar user={user} />}
          {token.user_type === userType.tso || token.user_type === userType.team_leader || token.user_type === userType.floor_manager ? <SalesSidebar user={user} /> : null}
          {token.user_type === userType.sales_executive ? supervisor ?<FieldSidebar user={user} />:<SalesSidebar user={user} /> :null}
          {token.user_type === userType.renewal_coordinator || token.user_type === userType.service_support ? supervisor ?<BackendSidebar user={user} />:<SalesSidebar user={user} /> :null}
          {token.user_type === userType.operation_manager ? supervisor ?<OperationSidebar user={user} />:<SalesSidebar user={user} /> :null}

          <div className='sidebarBottom'>
            {showDropDownNotification &&
              <div style={{ position: 'absolute' }}>
                <div className="more_block2" style={{ backgroundColor: color.secondaryBg, marginTop: token.user_type === userType.admin ? "-205px" : token.user_type === userType.tso ? "-130px" : '-175px' }} onClick={(e) => e.stopPropagation()}>

                  <div>
                    <Link to={AdminPath.PROFILE + '/' + token.id} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                      <Icon icon="iconamoon:profile-circle-fill" color={color.blue} fontSize={18} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Profile</div>
                      </div>
                    </Link>
                  </div>

                  {token.user_type !== userType.admin && token.user_type !== userType.tso ?
                    !supervisor ?
                      <div style={{ marginTop: '-10px' }}>
                        <Link onClick={() => { setShowDropDownNotification(false); handleChangeSupervisor(true) }} className="d-flex notification_wrapper align_center" >
                          <Icon icon="icon-park:switch" color={color.blue} fontSize={16} />
                          <div style={{ marginLeft: '10px' }}>
                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Supervisor</div>
                          </div>
                        </Link>
                      </div>

                      :

                      <div style={{ marginTop: '-10px' }}>
                        <Link onClick={() => { setShowDropDownNotification(false); handleChangeSupervisor(false) }} className="d-flex notification_wrapper align_center" >
                          <Icon icon="icon-park:switch" color={color.blue} fontSize={16} />
                          <div style={{ marginLeft: '10px' }}>
                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>{token.email}</div>
                          </div>
                        </Link>
                      </div>

                    : null}

                  {token.user_type === userType.admin && <>
                    <div style={{ marginTop: '-10px' }}>
                      <Link to={AdminPath.USERPERMISSION} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                        <Icon icon="ic:outline-privacy-tip" color={color.green} fontSize={18} />
                        <div style={{ marginLeft: '10px' }}>
                          <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>User Permissions</div>
                        </div>
                      </Link>
                    </div>

                    <div style={{ marginTop: '-10px' }}>
                      <Link to={AdminPath.SETTINGS} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                        <Icon icon="lucide:settings" color={color.blue2} fontSize={18} />
                        <div style={{ marginLeft: '10px' }}>
                          <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Settings</div>
                        </div>
                      </Link>
                    </div>
                  </>
                  }

                  <div style={{ marginTop: '-10px' }}>
                    <div className="d-flex notification_wrapper align_center" onClick={handleLogout}>
                      <Icon icon="heroicons-outline:logout" color={color.yellow} fontSize={18} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Log Out</div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '-10px' }}>
                    <div className="d-flex notification_wrapper align_center" onClick={() => setShowDropDownNotification(false)}>
                      <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '' }} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Close</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            }

            <div className="myrow align_center">
              <div><Avatar src={user ? mediaUrl + user.proimg : ''} sx={{ bgcolor: "#fff", color: "#0C768A", fontSize: 14, fontWeight: 700, width: '40px', height: '40px', border: "3px solid #fff" }} /></div>
              <div className='px-2'>
                <div className="reportingTitle">
                  {user ? user.name : ''}
                </div>
              </div>

              <div style={{ marginLeft: 'auto', color: "#fff" }} type='button' onClick={() => setShowDropDownNotification(!showDropDownNotification)}><MoreVertIcon /></div>
            </div>
          </div>

        </div >

      </>
    )
  } else {
    return null
  }
}

export default Sidebar