import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useClaimStore = create((set) => ({
    claims: [],claimsOther:null,
    fetchClaims: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/view-claim/'+ supervisor + '/?' +payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setClaims: async (data) => {
        set({ claims: data.data,claimsOther:{count: Number(data.count), currentPage: Number(data.currentPage)} });
    },
    createClaim: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/create-claim/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateClaim: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-claim/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    deleteClaim: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-claim/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
}));