import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useResearchUpdateStore = create((set) => ({
    researchUpdate: [],
    fetchResearchUpdate: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'research/view-research-update/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        let sorted = data.sort((a, b) => {
            return a.id - b.id;
          });
        set({ researchUpdate: sorted });
    },
    createResearchUpdate: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/create-research-update/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateResearchUpdate: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'research/update-research-update/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteResearchUpdate: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'research/delete-research-update/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));