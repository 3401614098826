import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'
import { useUserBankDetailsStore } from '../../../../../Store/UserStore/UserBankDetailsStore'

const BankDetails = (props) => {
  const { color, isLoading, setIsLoading, currentUser, permission } = props
  const { users, fetchUsers } = useUserStore()
  const { fetchUsersBankDetails, createUsersBankDetails, usersBankDetails } = useUserBankDetailsStore()
  const { addToast } = useToasts()
  const [error, setError] = useState(false)
  const [payload, setPayload] = useState({
    user: currentUser.id,
    bank_name: usersBankDetails.bank_name,
    ifsc: usersBankDetails.ifsc,
    ac_no: usersBankDetails.ac_no,
    upi: usersBankDetails.upi,
  })

  const handleSubmit = () => {
    if (payload.bank_name.length === 0 || payload.ifsc.length === 0 || payload.ac_no.length === 0 || payload.upi.length === 0) {
      setError(true)
    } else {
      setError(false)
      createUsersBankDetails(payload)
        .then(res => {
          fetchUsersBankDetails(currentUser.id)
          setIsLoading(false)
          setPayload({
            ...payload,
            user: currentUser.id,
            bank_name: payload.bank_name,
            ifsc: payload.ifsc,
            ac_no: payload.ac_no,
            upi: payload.upi,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  useEffect(() => {
    if (usersBankDetails) {
      setPayload({
        ...payload,
        user: currentUser.id,
        bank_name: usersBankDetails.bank_name,
        ifsc: usersBankDetails.ifsc,
        ac_no: usersBankDetails.ac_no,
        upi: usersBankDetails.upi,
      })
    } else {
      setPayload({
        ...payload,
        user: currentUser.id,
        bank_name: "",
        ifsc: "",
        ac_no: "",
        upi: "",
      })
    }
  }, [usersBankDetails])

  useEffect(() => {
    fetchUsersBankDetails(currentUser.id)
  }, [])

  return (
    <>
      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Bank Name <span className="text-danger">*</span></label>
            <input placeholder='Enter bank name' value={payload.bank_name} disabled={!permission} onChange={(e) => setPayload({ ...payload, bank_name: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>


          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Enter IFSC Code <span className="text-danger">*</span></label>
            <input placeholder='Enter ifsc code' value={payload.ifsc} disabled={!permission} onChange={(e) => setPayload({ ...payload, ifsc: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Account Number <span className="text-danger">*</span></label>
            <input placeholder='Enter Account Number' value={payload.ac_no} disabled={!permission} onChange={(e) => setPayload({ ...payload, ac_no: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>UPI ID <span className="text-danger">*</span></label>
            <input placeholder='Enter Upi ID' value={payload.upi} disabled={!permission} onChange={(e) => setPayload({ ...payload, upi: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

        </div>

        {permission &&
          <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>

              : "Submit"}</button>
          </div>
        }
      </div>
    </>
  )
}

export default BankDetails