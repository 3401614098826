import { create } from 'zustand';
import axios from 'axios';
import { url } from './../Export';

export const useLoginStore = create((set) => ({
    user: [],
    loginUser: async (payload) => {
        const response = await axios.post(url + 'auth/crm-login/', payload);
        const data = await response.data;
        return data
    },
}));