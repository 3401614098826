import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLeadStore = create((set) => ({
    leads: [], leadsList: [], leadsOther: null,
    fetchLeads: async (payload) => {
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-lead-enquiry/'+supervisor+'/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setLeads: async (data) => {
        let d = [], enq = []
        for (let i = 0; i < data.data.length; i++) {
            const e = data.data[i];
            d.push({ id: e.user.id, name: e.user.name, email: e.user.email, contact: e.user.contact, created_at: e.user.created_at })
            enq.push({ id: e.id, lead_c:e.lead_c,assign_time: e.assign_time, user: e.user.id, assign_to: e.assign_to.id, created_at: e.created_at, follow_up_state: e.follow_up_state, service_state: e.service_state, follow_up_time: e.follow_up_time, last_followup: e.last_followup, isLead: e.isLead, lead_created: e.lead_created,callback_date:e.callback_date })
        }
        set({ leads: d, leadsList: enq, leadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    updateLeadsList: async (alLeadData, payload) => {
        let items = [...alLeadData];
        for (let i = 0; i < payload.length; i++) {
            const e = payload[i];
            var foundIndex = await items.findIndex(x => x.id === e.id);
            items[foundIndex] = e
        }
        set({ leadsList: items });
    },
    removeLeadsList: async (alLeadData, payload) => {
        let items = [...alLeadData];
        let d = []
        for (let i = 0; i < items.length; i++) {
            const el = items[i];
            if (payload.includes(el.id)) {

            } else {
                d.push(el)
            }
        }
        set({ leads: d });
    },

}));