import { create } from 'zustand';
import { createRipples } from 'react-ripples'

const colors = {
    light: {
        blue: '#0C768A',
        primaryBg: "#F5F8F9",
        secondaryBg: "#FFFFFF",
        lightText: "#83838d",
        darkText: "#47484A",
        rippleColor: "rgba(0,0,0,0.2)",
        red:"#ED5E49",
        blue2:"#4E9DEF",
        green:"#38C786",
        yellow:"#E0AB3B",
        borderColor:"#d3d6da",
        tableHeader:"#F2F6F7",
        inputBackground:'#fff',
        tableBorder:"#e4e4e4",
        tableBorder2:"#eff5f6",
    },
    dark: {
        blue: '#0C768A',
        primaryBg: "#0E1217",
        secondaryBg: "#182028",
        lightText: "#adadb6",
        darkText: "#eff5f6",
        rippleColor: "rgba(255,255,255,0.2)",
        red:"#ED5E49",
        green:"#38C786",
        blue2:"#4E9DEF",
        yellow:"#E0AB3B",
        borderColor:"#3b4855",
        tableHeader:"#2A333F",
        inputBackground:'#1F262F',
        tableBorder:"#242c36",
        tableBorder2:"#242c36",
    },
};

export const useColorStore = create((set) => ({
    color: colors.light,
    fetchColor: async () => {
        let theme = localStorage.getItem('theme')
        if (theme) {
            theme = JSON.parse(theme)
            let newColor = theme === "dark" ? colors.dark : colors.light
            set({ color: newColor });
        }else{
            set({ color: colors.light });
        }
    },

}));



