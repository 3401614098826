import React, { useState, useEffect, useRef } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import DynamicEmailList from './DynamicEmailList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../../Loading';
import { useDynamicEmailStore } from '../../../../Store/SpaceStore/DynamicEmailStore';
import EmailEditor from 'react-email-editor';

const DynamicEmail = () => {
    const emailEditorRef = useRef(null);
    const { addToast } = useToasts();
    const { email, fetchDynamicEmail, createDynamicEmail } = useDynamicEmailStore()
    const { color } = useColorStore()
    const [create, setCreate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const [payload, setPayload] = useState({
        name:'',
        subject:'',
    })

    const [error, setError] = useState(false)
    const [content, setcontent] = useState("")
    const [jdata, setJdata] = useState("")

    const handleSubmit = async () => {
        if (payload.name.length === 0 || payload.subject.length===0) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            await emailEditorRef.current?.editor?.exportHtml((design) => {
                const {html} = design
                setcontent(html)
            });
            await emailEditorRef.current?.editor?.saveDesign((design) => {
                setJdata(JSON.stringify(design))
            });
            let data = {
                email_name:payload.name,
                email_subject:payload.subject,
                content:content,
                jdata:jdata,
            }
            await createDynamicEmail(data)
                .then(res => {
                    fetchDynamicEmail()
                    setIsLoading(false)
                    setPayload({...payload,name:'',subject:''})
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })

        }
    }

    useEffect(() => {
        fetchDynamicEmail()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["email_name","email_subject"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }


    return (
        <>
            {!create ?
                <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="myrow justify_space_between mb-4">
                        <div className="ico-search" style={{ position: 'relative' }}>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="primaryButton" onClick={() => setCreate(true)} >Create</button>
                        </Ripples>
                    </div>

                    {/* ------------------------------Table Work-------------------------------------- */}

                    <div class="table-responsive">
                        <table class="table table-nowrap align-middle mb-0">
                            <thead style={{ backgroundColor: color.tableHeader }}>
                                <tr style={{ color: color.darkText }}>
                                    <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                        S.No.
                                    </th>
                                    <th style={{ color: color.darkText }} scope="col">Name</th>
                                    <th style={{ color: color.darkText }} scope="col">Subject</th>
                                    <th style={{ color: color.darkText }} scope="col">View</th>

                                    <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {email.length > 0 ?
                                    search(email).map((c, count) => {
                                        return <DynamicEmailList data={c} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    })
                                    :
                                    null}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                // -------------------------------------------------------------------------------------------------------------
                // -------------------------------------------------------------------------------------------------------------

                <div className="category_body" style={{ backgroundColor: "#fff" }}>
                    <div className="myrow mb-4">
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="secondaryButton" onClick={() => setCreate(false)} >Back</button>
                        </Ripples>
                    </div>

                    {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                    <div className='mb-3'>
                        <label className='modal_label'>Email Name <span className="text-danger">*</span></label>
                        <input placeholder='Enter Email Name' value={payload.name} onChange={(e) => setPayload({...payload,name:e.target.value})} style={{ border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className='mb-4'>
                        <label className='modal_label'>Subject <span className="text-danger">*</span></label>
                        <input placeholder='Enter Subject' value={payload.subject} onChange={(e) => setPayload({...payload,subject:e.target.value})} style={{ border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label'>Content <span className="text-danger">*</span></label>
                        <EmailEditor ref={emailEditorRef} />
                    </div>

                    <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                        <>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                            Please wait...
                        </>

                        : "Save"}</button>

                </div>
            }
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            {/* <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Qr Codes</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>File Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter File Name' value={filename} onChange={(e) => setFilename(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>File <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default DynamicEmail