import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../../Loading';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from './../../../../Store/ColorStore';
import { useLeadImportStore } from '../../../../Store/LeadStore/LeadImportStore';
import { useUserStore } from '../../../../Store/UserStore/UserStore';
import { useLeadActionStore } from '../../../../Store/LeadStore/LeadAction';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLeadServiceStore } from '../../../../Store/ServiceStore/LeadsServiceStore';
import { useProductStore } from './../../../../Store/ProductStore/ProductStore';
import { useUserPermissionStore } from './../../../../Store/UserPermissionStore/UserPermissionStore';
import { userSegrigation, userType } from '../../../../Export';

const ServiceList = React.lazy(() => { return import('./ServiceList') });
const animatedComponents = makeAnimated();


const Services = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { allLeadsList, allLeads, fetchAllLeads } = useLeadImportStore()
    const { product, fetchProduct } = useProductStore()
    const { leadServices, fetchLeadServices, updateDoneCompliance } = useLeadServiceStore()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["name", 'contact']);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                        :null
                );
            });
        });
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
        if (product.length === 0) {
            fetchProduct()
        }
        if (allLeads.length === 0 || allLeadsList.length === 0) {
            fetchAllLeads()
        }

    }, [])
    useEffect(() => {
        if (leadServices.length === 0 || allLeadsList.length === 0) {
            fetchLeadServices()
        }
    }, [])

    const [data, setData] = useState([])

    useEffect(() => {
        if (allLeads.length > 0 && leadServices.length > 0) {
            let d = []
            for (let i = 0; i < leadServices.length; i++) {
                const el = leadServices[i];
                let enq = allLeadsList.find((f) => {
                    return f.id === el.enquiry
                })
                let cUser
                if (enq) {
                    cUser = allLeads.find((f) => {
                        return enq.user === f.id
                    })
                }

                if (enq && cUser) {
                    d.push(
                        {
                            el, enq, ...cUser
                        }
                    )
                }
            }
            setData(d)
        }
    }, [allLeads, leadServices])

    // -----------------Permission Work------------------
    let token = JSON.parse(localStorage.getItem('user'))
    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)
    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'SERVICE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? currentItems.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };


    // ======================================Filter Works=======================================
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };
    const [filterMode, setFilterMode] = useState(false)
    const [filterData, setFilterData] = useState([])
    const [changeNotify, setChangeNotify] = useState(false)
    const [type, setType] = useState('')
    const [filterProduct, setFilterProduct] = useState([])
    const [filterLeadOwner, setFilterLeadOwner] = useState([])
    const [complianceStatus, setComplianceStatus] = useState('')
    const [status, setStatus] = useState("")
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    const [options, setOptions] = useState([])
    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < product.length; i++) {
            const u = product[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [product])

    const [leadOwnerOptions, setLeadOwnerOptions] = useState([])
    useEffect(() => {
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setLeadOwnerOptions(d)
    }, [users])

    const handleRemoveFilterLeadOwner = (index) => {
        setFilterLeadOwner([
            ...filterLeadOwner.slice(0, index),
            ...filterLeadOwner.slice(index + 1, filterLeadOwner.length)
        ]);
    }
    const handleRemoveFilterProduct = (index) => {
        setFilterProduct([
            ...filterProduct.slice(0, index),
            ...filterProduct.slice(index + 1, filterProduct.length)
        ]);
    }

    const handleFilter = () => {
        let ndata = data
        if (status.length > 0) {
            ndata = ndata.filter((f) => {
                return f.el.service_state === status;
            });
        }
        if (complianceStatus.length > 0) {
            ndata = ndata.filter((f) => {
                return f.el.compliance === complianceStatus;
            });
        }
        if (type.length > 0) {
            ndata = ndata.filter((f) => {
                return f.el.service_status === type;
            });
        }
        if (dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            ndata = ndata.filter((f) => {
                const date = new Date(f.el.created_at).setHours(0, 0, 0, 0)
                return date <= new Date(dateRange.endDate).setHours(0, 0, 0, 0) && new Date(dateRange.startDate).setHours(0, 0, 0, 0) <= date
            });
        }
        if (filterProduct.length > 0) {
            let d = []
            for (let i = 0; i < filterProduct.length; i++) {
                const e = filterProduct[i];
                d.push(e.value)
            }
            ndata = ndata.filter((product) => {
                return d.indexOf(product.el.product_id) > -1;
            });
        }
        if (filterLeadOwner.length > 0) {
            let d = []
            for (let i = 0; i < filterLeadOwner.length; i++) {
                const e = filterLeadOwner[i];
                d.push(Number(e.value))
            }
            ndata = ndata.filter((f) => {
                return d.indexOf(Number(f.enq.assign_to)) > -1;
            });
        }
        setFilterData(ndata)
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0 && leadOwnerOptions.length>0) {
            const filterParams = new URLSearchParams(location.search);
            const nowner = filterParams.get('owner');
            const nfilterProduct = filterParams.get('filterProduct');
            const nstatus = filterParams.get('status');
            const ncomplianceStatus = filterParams.get('complianceStatus');
            const ntype = filterParams.get('type');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            setType(ntype)
            setComplianceStatus(ncomplianceStatus)
            setStatus(nstatus)
            setFilterLeadOwner(leadOwnerOptions.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setFilterProduct(options.filter((f) => {
                return nfilterProduct.split(',').indexOf(String(f.value)) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options, leadOwnerOptions, data])
    

    const applyFilter = () => {
        setChangeNotify(false)
        if (type.length > 0 || filterProduct.length > 0 || status.length > 0 || filterLeadOwner.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0 || complianceStatus.length > 0) {
            setFilterMode(true)
            navigate(`?owner=${filterLeadOwner.map((f) => { return f.value })}&filterProduct=${filterProduct.map((f) => { return f.value })}&status=${status}&complianceStatus=${complianceStatus}&type=${type}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
            handleFilter()
        } else {
            clearFilter()
        }
    }

    const handleStatusChange = () => {
        setStatus('')
        setChangeNotify(true)
    }
    const handleTypeChange = () => {
        setType('')
        setChangeNotify(true)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    const clearFilter = () => {
        setStatus('')
        setComplianceStatus("")
        setFilterProduct([])
        setFilterLeadOwner([])
        setType("")
        navigate('')
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        setFilterMode(false)
        setFilterData([])
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(data).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(data).length / itemsPerPage));
            setitemLength(search(data).length)
            setItemEndSet(endOffset)
        }
        else if (filterMode) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(filterData.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(filterData.length / itemsPerPage));
            setitemLength(filterData.length)
            setItemEndSet(endOffset)
        } else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(data.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(data.length)
        }

    }, [itemOffset, itemsPerPage, filterMode, filterData, searchInput, data]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };


    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <div className="myrow">
                        {permission ? <>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                <button data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="infoButton right_border_radius" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                            </Ripples>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                <CSVLink data={data} filename='All-Leads.csv'><button className="successButton left_border_radius right_border_radius"><Icon icon="ph:export-fill" fontSize={16} /> Export</button></CSVLink>
                            </Ripples>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={1000}>
                                <button className="dangerButton left_border_radius" ><Icon icon="basil:trash-solid" fontSize={16} /> Delete</button>
                            </Ripples>
                        </>
                            :
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                                <button data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                            </Ripples>
                        }
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>
                        {status.length > 0 && <div className="filter_values">Status : {status === "A" ? 'Active' : status === "P" ? 'Pending' : status === "E" ? 'Expire' : null} <Icon icon="fa6-solid:x" onClick={handleStatusChange} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>}
                        {complianceStatus.length > 0 && <div className="filter_values">Compliance Status : {complianceStatus === "P" ? 'Pending' : complianceStatus === "D" ? 'Done' : null} <Icon icon="fa6-solid:x" onClick={() => { setComplianceStatus(''); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>}
                        {type.length > 0 ?
                            <div className="filter_values" >Service Type : {type === "P" ? 'Paid' : type === "F" ? 'Free Trial' : type === "R" ? 'Renew' : null} <Icon icon="fa6-solid:x" onClick={handleTypeChange} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }
                        {filterProduct.length > 0 ?
                            filterProduct.map((u, count) => {
                                return <div className="filter_values" key={count}>Product : {u.label} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterProduct(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })

                            : null
                        }
                        {filterLeadOwner.length > 0 ?
                            filterLeadOwner.map((u, count) => {
                                return <div className="filter_values" key={count}>Lead Owner : {u.label} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterLeadOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })

                            : null
                        }
                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                Date Range : {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="table-responsive mt-3">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '40px', color: color.darkText }}>
                                    <input checked={currentItems.every((e, index) => e.id === checked[index]) && itemLength > 0} onChange={handleCheckAllChange} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                                    {/* <label className="form-check-label" for="contacusercheck"></label> */}
                                </th>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Contact Info</th>
                                <th style={{ color: color.darkText }} scope="col">Lead Owner</th>
                                <th style={{ color: color.darkText }} scope="col">Payment Recieved By</th>
                                <th style={{ color: color.darkText }} scope="col">Segment</th>
                                <th style={{ color: color.darkText }} scope="col">Status</th>
                                <th style={{ color: color.darkText }} scope="col">Last Follow Up</th>
                                {token.user_type === userType.compliance || token.user_type === userType.admin || token.user_type === userType.sub_admin ?
                                    <th style={{ color: color.darkText }} scope="col">Compliance Process</th>
                                    : null}
                                {/* <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {data.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <Suspense key={c.el.id} fallback={<></>}>
                                        <ServiceList allLeads={allLeads} updateDoneCompliance={updateDoneCompliance} setChangeNotify={setChangeNotify} fetchLeadServices={fetchLeadServices} users={users} product={product} allLeadsList={allLeadsList} checked={checked} handleSingleChange={handleSingleChange} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">
                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Service Type</label>
                        <select value={type} onChange={(e) => setType(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----Choose Service Type-----</option>
                            <option value="F">Free Trial</option>
                            <option value="P">Paid</option>
                            <option value="R">Renew</option>
                        </select>
                    </div>

                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Service Status</label>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----Choose Service Status-----</option>
                            <option value="A">Active</option>
                            <option value="E">Expire</option>
                            <option value="P">Pending</option>
                        </select>
                    </div>

                    {token.user_type === userType.compliance || token.user_type === userType.admin ?
                        <div className='mb-3'>
                            <label className='modal_label' style={{ color: color.darkText }}>Compliance Status</label>
                            <select value={complianceStatus} onChange={(e) => setComplianceStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                <option value="">-----Choose Compliance Status-----</option>
                                <option value="P">Pending</option>
                                <option value="D">Done</option>
                            </select>
                        </div>
                        : null}

                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Lead Owner</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={leadOwnerOptions}
                            onChange={(e) => setFilterLeadOwner(e)}
                            value={filterLeadOwner}
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Product</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => setFilterProduct(e)}
                            value={filterProduct}
                        />
                    </div>

                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Payment Recived Date Range</label>
                        <div className="d-flex justify_space_between">
                            <div style={{ width: '48%' }}>
                                <label className='modal_label' style={{ color: color.lightText }}>From Date</label>
                                <input type='date' value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div style={{ width: '48%' }}>
                                <label className='modal_label' style={{ color: color.lightText }}>To Date</label>
                                <input type='date' value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">

                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {/* ------------------------------Delete modal----------------------------------- */}

            {/* <div className="modal fade" id={"leadTrashModal"} tabIndex="-1" aria-labelledby="leadTrashModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Trash selected Leads ?</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadTrash}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Proceed"}</button>
                        </div>
                    </div>
                </div>
            </div> */}

            {isLoading && <Loading />}
        </>
    )
}

export default Services