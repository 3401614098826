import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useWebEnquiryStore = create((set) => ({
    webEnquiry: [],
    fetchWebEnquiry: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-get-in-touch/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ webEnquiry: data });
    },
    deleteWebEnquiry: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-get-in-touch/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));