import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useTaskStore = create((set) => ({
    tasks:[],tasksOther:null,
    fetchTask: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-task/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchAssignedTask: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-assigned-task/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    createTask: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-task/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateTask: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/update-task/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    addTaskRemark: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/add-task-remark/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    setTask: async (data) => {    
        set({ tasks: data.data, tasksOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    clearTaskData: async (data) => {    
        set({ tasks: [], tasksOther: null })
    },

}));