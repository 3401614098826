import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const usePaymentUserStore = create((set) => ({
    paymentUser: [],
    fetchPaymentUser: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-payment-user/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ paymentUser: data });
    },
    
}));