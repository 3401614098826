import React, { useState, useEffect } from 'react'
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useColorStore } from '../../../Store/ColorStore';
import { useToasts } from 'react-toast-notifications';

const UserPermissionsList = (props) => {
    const { addToast } = useToasts()
    const { d, permissionOptions, tabs, updateUserPermission, fetchUserPermissions, data } = props
    const { color } = useColorStore()
    const getPermissionName = (name) => {
        let d = permissionOptions.find((f) => {
            return f.value === name
        })
        return d.label
    }
    const [status, setStatus] = useState(d[data.name])
    
    useEffect(() => {
      setStatus(d[data.name])
    }, [data,d])
    

    const handleChange = (e) => {
        let payload = {
            d: d.id, tabs: tabs, status: e.target.checked
        }
        updateUserPermission(payload)
            .then(res => {
                fetchUserPermissions()
                setStatus(payload.status)
                addToast('Success...', { appearance: 'success' });
            })
            .catch(err => {
                addToast('Something went wrong...', { appearance: 'error' });
            })
    }
    return (
        <>

            < div className="d-flex justify_space_between">
                <div className="per_block1" style={{ color: color.darkText }}>
                    {getPermissionName(d.permission_name)}
                </div>

                <div className="per_block2">
                    <Switch sx={{ m: 1 }} color='secondary' checked={status} onChange={handleChange} />
                </div>
            </div>

        </>
    )
}

export default UserPermissionsList