import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { useAssignedLeadStore } from '../../../Store/LeadStore/AssignedLeadStore';
import { useTaskStore } from './../../../Store/TaskStore/TaskStore';
import { CSVLink } from "react-csv";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { leadFollowUpOptions, userSegrigation, userType } from '../../../Export';
const TaskList = React.lazy(() => { return import('./TaskList') });
const animatedComponents = makeAnimated();


const Task = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { fetchTask, tasks, fetchAssignedTask, setTask, tasksOther, createTask, clearTaskData } = useTaskStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])


    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState([])
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    // -------Options-----------
    const [options, setOptions] = useState([])

    const leadServiceStateOptions = [
        { value: 'P', label: 'Paid' },
        { value: 'R', label: 'Renew' },
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus('');
    }

    useEffect(() => {
        if (location.search.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nstatus = filterParams.get('status');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nsearch = filterParams.get('search');
            setStatus(nstatus)
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterServiceState.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            navigate(`${u}`);
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterServiceState([])
        setStatus([])
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        navigate('')
        setFilterMode(false)
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? tasks.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (tasksOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(tasksOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(tasksOther.count)
        }
    }, [tasksOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % tasksOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************

    // *************************************************Main Url Work****************************************************
    const [currentTab, setCurrentTab] = useState(true)

    useEffect(() => {
        if (currentTab) {
            let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            setIsLoading(true)
            fetchTask(u)
                .then(res => {
                    setIsLoading(false)
                    setTask(res)
                })
                .catch(err => {
                    setIsLoading(false)
                })
        } else {
            let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            setIsLoading(true)
            fetchAssignedTask(u)
                .then(res => {
                    setIsLoading(false)
                    setTask(res)
                })
                .catch(err => {
                    setIsLoading(false)
                })
        }
    }, [reload, itemsPerPage, currentPage, filterToggle, currentTab])

    // *******************************

    const [payload, setPayload] = useState({
        name: '', desc: '', assign_to: '', owner: String(token.id),f:[]
    })

    const handleSubmit = () => {
        if (payload.name.length > 0 && payload.desc.length > 0 && payload.assign_to.length > 0) {
            setIsLoading(true)
            let formData = new FormData()
            formData.append('name',payload.name)
            formData.append('desc',payload.desc)
            formData.append('assign_to',payload.assign_to)
            formData.append('owner',payload.owner)
            for (let i = 0; i < payload.f.length; i++) {
                const element = payload.f[i];
                formData.append('file',element)
            }
            createTask(formData)
                .then(res => {
                    setIsLoading(false)
                    setReload(!reload)
                    setPayload({ ...payload, name: '', desc: '', assign_to: '', owner: String(token.id) ,f:[]})
                    addToast('Success...', { appearance: 'success' });

                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        } else {
            addToast('All fields required...', { appearance: 'error' });
        }
    }

    const handleChange = (d) => {
        setCurrentTab(d)
        clearTaskData()
        setCurrentPage(1)
    }

    return (
        <>

            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                    <div className="myrow">
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block me-2' during={800}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#createModal" ><Icon icon="mdi:plus" fontSize={15} /> Create Task</button>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                            <button data-toggle="tooltip" data-placement="top" title="Filter" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                        </Ripples>
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

                        {status.length > 0 ?
                            <div className="filter_values">{status === 'P' ? 'Pending' : status === 'C' ? 'Completed' : ''} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterStatus(); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }

                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="myrow py-2">
                    <button style={{color:currentTab?'white':color.darkText}} onClick={() => handleChange(true)} className={currentTab ? "primaryButton me-2" : 'lightButton me-2'}>
                        Self
                    </button>
                    <button style={{color:!currentTab?'white':color.darkText}} onClick={() => handleChange(false)} className={!currentTab ? "primaryButton me-2" : 'lightButton me-2'}>
                        Assigned
                    </button>
                </div>

                <div className="table-responsive mt-3 table-bordered table-striped">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {tasksOther ? tasksOther.count : 0}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '40px', color: color.darkText }}>
                                    <input checked={tasks.every((e, index) => e.id === checked[index]) && itemLength > 0} onChange={handleCheckAllChange} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                                    {/* <label className="form-check-label" for="contacusercheck"></label> */}
                                </th>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Task Name</th>
                                <th style={{ color: color.darkText }} scope="col">Task Description</th>
                                <th style={{ color: color.darkText }} scope="col">Task Owner</th>
                                <th style={{ color: color.darkText }} scope="col">Assigned to</th>
                                <th style={{ color: color.darkText }} scope="col">Status</th>
                                <th style={{ color: color.darkText }} scope="col">Attachments</th>
                                <th style={{ color: color.darkText }} scope="col">Remark</th>
                                {currentTab &&
                                    <th scope="col" style={{ color: color.darkText }}>Action</th>
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {tasks.length > 0 ?
                                tasks.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <TaskList currentTab={currentTab} reload={reload} setReload={setReload} users={users} checked={checked} handleSingleChange={handleSingleChange} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {tasks.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {/* ------------------------------Add modal----------------------------------- */}

            <div class="modal fade" id="createModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Task</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter task name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Assign Task <span className="text-danger">*</span></label>
                                <select value={payload.assign_to} onChange={(e) => setPayload({ ...payload, assign_to: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">------select user to assign-------</option>
                                    {users.map((m) => {
                                        return <option key={m.id} value={m.id}>{m.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Description <span className="text-danger">*</span></label>
                                <textarea placeholder='Enter description' value={payload.desc} onChange={(e) => setPayload({ ...payload, desc: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach Files</label>
                                <input type='file' multiple placeholder='Add files' onChange={(e) => setPayload({ ...payload, f: e.target.files })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter User</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">

                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Status</label>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----select status-----</option>
                            <option value="P">Pending</option>
                            <option value="C">Completed</option>
                        </select>
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Task Created Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">
                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default Task