import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useDynamicEmailStore = create((set) => ({
    email: [],
    fetchDynamicEmail: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/view-email/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ email: data });
    },
    createDynamicEmail: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'product/create-email/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateDynamicEmail: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'product/update-email/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
    deleteDynamicEmail: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'product/delete-email/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    }
}));