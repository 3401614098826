import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useProductStore } from '../../../Store/ProductStore/ProductStore';
import { useToasts } from 'react-toast-notifications';

const ProductList = (props) => {
    const { addToast } = useToasts();
    const { updateProduct, deleteProduct, fetchProduct } = useProductStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, category, subcategory, permission } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        name: data.name,
        code: data.code,
        category: data.category,
        subcategory: data.subcategory,
        monthly: data.monthly,
        quaterly: data.quaterly,
        premium: data.premium,
        web_visible: data.web_visible,
        active: data.active,
    })

    const handleSubmit = () => {
        if (payload.name.length === 0 || payload.code.length === 0 || payload.monthly.length === 0 || payload.quaterly.length === 0 || payload.category.length === 0 || payload.subcategory.length === 0) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            updateProduct(payload, data.id)
                .then(res => {
                    fetchProduct()
                    setIsLoading(false)
                    setPayload({ ...payload, code: payload.code, name: payload.name })
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn")
        d.click()
        setIsLoading(true)
        deleteProduct(data.id)
            .then(res => {
                fetchProduct()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const findCategory = (id) => {
        let d = category.find((f) => f.id === id)
        if (d) {
            return d.name
        }
    }

    const findSubCategory = (id) => {
        let d = subcategory.find((f) => f.id === id)
        if (d) {
            return d.name
        }
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    <a style={{ color: color.darkText }}>{data.name}</a>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.code}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{findCategory(data.category)} ({findSubCategory(data.subcategory)})</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>M - {data.monthly} <br /> Q - {data.quaterly}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.premium ? "Yes" : 'No'}</td>
                {/* <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.web_visible ? "Yes" : 'No'}</td> */}
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.active ? "Yes" : 'No'}</td>
                {permission &&
                    <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                            </li>
                            <li class="list-inline-item">
                                <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                            </li>
                        </ul>
                    </td>
                }
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Product</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }} id='style-3'>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Code <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Code' value={payload.code} onChange={(e) => setPayload({ ...payload, code: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.category} onChange={(e) => setPayload({ ...payload, category: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select Category-------------</option>
                                    {category.map((d) => {
                                        return <option value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Sub Category <span className="text-danger">*</span></label>
                                <select value={payload.subcategory} onChange={(e) => setPayload({ ...payload, subcategory: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select Sub Category-------------</option>
                                    {subcategory.map((d) => {
                                        return <option value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Monthly Price <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Monthly Price' value={payload.monthly} onChange={(e) => setPayload({ ...payload, monthly: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Quarterly Price <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Quarterly Price' value={payload.quaterly} onChange={(e) => setPayload({ ...payload, quaterly: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Premium <span className="text-danger">*</span></label>
                                <select value={payload.premium} onChange={(e) => setPayload({ ...payload, premium: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Active <span className="text-danger">*</span></label>
                                <select value={payload.active} onChange={(e) => setPayload({ ...payload, active: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Web Visibility <span className="text-danger">*</span></label>
                                <select value={payload.web_visible} onChange={(e) => setPayload({ ...payload, web_visible: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>On</option>
                                    <option value={false}>Off</option>
                                </select>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.name} ) Product ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProductList