import React from 'react'
import { userType } from '../../Export'
import Dashboard from '../Sales/Dashboard/Dashboard'
import Dashboard2 from './../Admin/Dashboard/Dashboard';
import FieldDashboard from './FieldDashboard';
import RenewalDashboard from './RenewalDashboard';
import OperationDashboard from './OperationDashboard';

const Dash = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
    return (
        <>
            {token.user_type === userType.tso || token.user_type === userType.team_leader || token.user_type === userType.floor_manager || token.user_type === userType.service_support ? <Dashboard /> : null}
            {token.user_type === userType.admin && <Dashboard2 />}
            {token.user_type === userType.sales_executive ? supervisor ? <FieldDashboard /> : <Dashboard /> : null}
            {token.user_type === userType.renewal_coordinator ? supervisor ? <RenewalDashboard /> : <Dashboard /> : null}
            {token.user_type === userType.operation_manager ? supervisor ? <OperationDashboard /> : <Dashboard /> : null}
        </>
    )
}

export default Dash