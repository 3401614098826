import React, { useEffect, useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import { useFieldLeadStore } from '../../../Store/LeadStore/FieldLeadStore'
import { Months, userType, companyOptions, fileUrl, planOptions, productOptions, sumOptions } from '../../../Export'
import { AdminPath } from './../../Admin/AdminRoutes/AdminPath';
import { useMisStore } from '../../../Store/MisStore/MisStore'
import Swal from 'sweetalert2'
import { useRenewStore } from '../../../Store/RenewStore/RenewStore'
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

const RenewDataList = (props) => {
  const { assignFieldLead } = useFieldLeadStore()
  let token = JSON.parse(localStorage.getItem('user'))
  const { data, count, isLoading, setIsLoading, handleSingleChange, checked, reload, setReload, users, pr } = props
  const { addFollowUpFieldLead, deleteFollowUpFieldLead, doneFollowUpFieldLead, fetchFieldLeadsFiles } = useFieldLeadStore()
  const { updateMis, uploadPolicy, markPaid } = useMisStore()
  const { markDoneRemark, addRenewRemark, lostRenew } = useRenewStore()
  const { addToast } = useToasts()
  const { color } = useColorStore()

  const findUser = (id) => {
    let f = users.find((d) => d.id === Number(id))
    return f ? f.name : '-'
  }

  const [payload, setPayload] = useState({
    enquiry: data.enquiry, company: data.company, type: data.type, month: data.month, policy_number: data.policy_number ? data.policy_number : '', policy_status: data.policy_status, source_by: String(data.source_by), picked_by: String(data.picked_by), proposed_by: data.proposed_by, sourcing_date: data.sourcing_date ? data.sourcing_date : '', login_date: data.login_date ? data.login_date : '', insured_person: data.insured_person, contact: data.contact, alt_contact: data.alt_contact ? data.alt_contact : '', mail: data.mail, product: data.product ? data.product : '', medical: data.medical ? data.medical : '', sum_assured: data.sum_assured ? data.sum_assured : '', plan: data.plan ? data.plan : '', prev_policy_number: data.prev_policy_number ? data.prev_policy_number : '', gross_premium: data.gross_premium ? data.gross_premium : '', gst: data.gst ? data.gst : '', net_premium: data.net_premium ? data.net_premium : '', payment_mode: data.payment_mode ? data.payment_mode : '', bank_name: data.bank_name ? data.bank_name : '', cheque_number: data.cheque_number ? data.cheque_number : '', cheque_date: data.cheque_date ? data.cheque_date : null, discount: data.discount ? data.discount : '', quote: data.quote ? data.quote : '', address: data.address ? data.address : '',
    start_date: data.start_date ? data.start_date : '', end_date: data.end_date ? data.end_date : ''
  })

  // -------------------------------------------------------------------------------

  const [error, setError] = useState(false)
  const [remark, setRemark] = useState('')
  const [callback_date, setCallback_date] = useState(null)

  const handleAddRemark = () => {
    setIsLoading(true)
    addRenewRemark({ remark, callback_date }, data.id)
      .then(res => {
        document.getElementById("closeBtn" + data.id).click()
        setIsLoading(false)
        setReload(!reload)
        addToast('Success...', { appearance: 'success' });
      })
      .catch(err => {
        setIsLoading(false)
      })
  }

  // --------------------------------------------------------------------------------

  const [user, setUser] = useState('')
  const [visitDate, setVisitDate] = useState()
  const handleAssign = () => {
    if (user.length > 0) {
      setIsLoading(true)
      setError(false)
      let d = document.getElementById('closeAssignBtn' + data.id)
      d.click()
      let da = {
        assign_to: user, enquiry: data.enquiry, rs: data.id, visitDate: visitDate
      }
      assignFieldLead(da)
        .then(res => {
          d.click()
          markDoneRemark(data.id)
          setUser('')
          setReload(!reload)
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Something went wrong...', { appearance: 'error' });
        })

    } else {
      setError(true)
    }
  }

  const handleLost = () => {
    setIsLoading(true)
    lostRenew(data.id)
      .then(res => {
        setReload(!reload)
        addToast('Marked as Lost.', { appearance: 'success' });
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        addToast('Something went wrong...', { appearance: 'error' });
      })
  }

  const findStatus = () => {
    let d = ''
    if (data.isDone) {
      d = 'Assigned'
    }
    if (data.isLost) {
      d = 'Lost'
    }
    if (data.isPaid) {
      d = 'Paid'
    }
    if (!data.isDone) {
      d = 'Unpaid'
    }

    return d
  }

  // -------------------------------------
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: color.inputBackground,
      // match with the menu
      borderRadius: 4,
      // Overwrittes the different states of border
      borderColor: color.borderColor,
      fontSize: 14,
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: color.borderColor
      }
    }),
    menu: base => ({
      ...base,
      background: color.inputBackground,
      color: color.darkText,
      fontSize: 14,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? color.blue : null,
        color: isFocused ? "#fff" : null,
        // color: "#333333"
      };
    }
  };
  const [payload2, setPayload2] = useState({
    company: '', plan: '', product: '', type: '', sum_assured: '', premium: '', medical: '', payment: 'ONLINE', enquiry: data.enquiry, rs: data.id
  })

  const handleMarkPaid = () => {
    if (String(payload2.enquiry).length > 0 && payload2.company.length > 0 && payload2.plan.length > 0 && payload2.product.length > 0 && payload2.type.length > 0 && payload2.sum_assured.length > 0 && payload2.premium.length > 0 && payload2.medical.length > 0 && payload2.payment.length > 0) {
      setIsLoading(true)
      markPaid(payload2)
        .then(res => {
          document.getElementById('closeAssignBtn2' + data.id).click()
          setReload(!reload)
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Something went wrong...', { appearance: 'error' });
        })
    } else {
      addToast('All fields are required...', { appearance: 'error' });
    }
  }

  return (
    <>
      <tr style={{ color: color.darkText }}>

        <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
          {count}
        </td>
        <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText, fontSize: '11px' }} scope="row">
          <b>{data.mis_category}</b> <br />
          <small class="d-inline-flex px-1 fw-medium text-success-emphasis bg-success-subtle border border-success-subtle rounded-2" style={{ fontSize: '12px', marginTop: '5px' }}>MBID - {data.enquiry}</small>
        </td>
        {/* <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.company}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.type === 'F' ? 'Fresh' : data.type === 'M' ? 'Migration' : data.type === 'R' ? 'Renewal' : data.type === 'P' ? 'Port' : '-'}
        </td> */}
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          <div>
            <small className='d-inline-flex mb-1 px-2 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2'>{findStatus()}</small>
          </div>
          {data.callback_time && <span style={{ color: color.red }}>{moment(data.callback_time).format('LLL')}</span>}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.proposed_by} <br />
          <Icon icon="fluent:call-28-regular" fontSize={12} color={color.blue} />{data.contact}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.insured_person}
        </td>

        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.policy_number ? data.policy_number : '-'}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px', minWidth: '150px' }}>
          ({data.start_date ? moment(data.start_date).format('LL') : '-'}) - ({data.end_date ? moment(data.end_date).format('LL') : '-'})
        </td>
        {/* <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.policy_status === 'P' ? <span class="badge bg-warning ms-2" style={{ fontSize: '15px' }}>Pending</span> : data.policy_status === 'I' ? <span class="badge bg-success ms-2" style={{ fontSize: '15px' }}>Issued</span> : <span class="badge bg-danger ms-2" style={{ fontSize: '15px' }}>Cancelled</span>}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {findUser(data.picked_by)}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {findUser(data.source_by)}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {moment(data.sourcing_date).format('L')}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {moment(data.login_date).format('L')}
        </td> */}
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.alt_contact ? data.alt_contact : '-'}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '20px', minWidth: '150px', fontSize: '11px' }}>
          {data.address ? data.address : '-'}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.product ? data.product : '-'}
        </td>
        {/* <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.medical ? data.medical : '-'}
        </td> */}
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.sum_assured ? data.sum_assured : '-'}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.plan ? data.plan : '-'}
        </td>
        <td style={{ color: color.green, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.gross_premium ? '₹' + data.gross_premium : '-'}
        </td>
        {/* <td style={{ color: color.green, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.gst ? '₹' + data.gst : '-'}
        </td> */}
        <td style={{ color: color.green, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
          {data.net_premium ? '₹' + data.net_premium : '-'}
        </td>
        <td style={{ color: color.lightText, borderBottom: '1px solid ' + color.tableBorder, fontSize: '12px', whiteSpace: 'pre-wrap', maxWidth: '180px' }}>
          <small type='button' data-bs-toggle="modal" className='mb-1' data-bs-target={"#addServiceFollowUpModal" + data.id} style={{ color: color.blue, paddingBottom: '5px' }}>
            <u>Add New...</u>
          </small>
          <br />
          {data.remark ? data.remark : ''}
        </td>
        <td style={{ color: color.lightText, borderBottom: '1px solid ' + color.tableBorder, minWidth: '130px' }}>
          <div className="d-flex">
            <div>
              <button className='primaryButton me-1' style={{ fontSize: '11px' }} data-bs-toggle="modal" data-bs-target={"#assignToFieldModal" + data.id}><Icon icon="mingcute:user-edit-line" fontSize={15} /></button>
            </div>
            {!data.isLost &&
              <div>
                <button className='dangerButton me-1' style={{ fontSize: '11px' }} onClick={handleLost}><Icon icon="healthicons:negative" fontSize={15} /></button>
              </div>
            }
            {!data.isPaid &&
              <div>
                <button className='successButton me-1' style={{ fontSize: '11px' }} data-bs-toggle="modal" data-bs-target={"#markPaidModal" + data.id}><Icon icon="ic:twotone-currency-rupee" fontSize={15} /></button>
              </div>
            }
          </div>
        </td>

      </tr>

      {/* ----------------------Follow Up Add Modal---------------------------- */}

      <div class="modal fade" id={"addServiceFollowUpModal" + data.id} tabindex="-1" aria-labelledby="addServiceFollowUpModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Add Remark</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please fill Required fields...!</div>}

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Callback Date<span className="text-danger">*</span></label>
                <input type='datetime-local' value={callback_date} onChange={(e) => setCallback_date(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Remark <span className="text-danger">*</span></label>
                <textarea value={remark} onChange={(e) => setRemark(e.target.value)} placeholder='Enter...' rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
              </div>

            </div>
            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id={'closeBtn' + data.id} style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleAddRemark}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Save"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------Assign to Field Modal---------------------------- */}

      <div class="modal fade" id={"assignToFieldModal" + data.id} tabindex="-1" aria-labelledby="assignToFieldModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="assignToFieldModalLabel">Forward</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Select user to assign <span className="text-danger">*</span></label>
                <select value={user} onChange={(e) => setUser(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="">-----Select Executive-----</option>
                  {users.filter(u => u.user_type === userType.sales_executive).map((d) => {
                    return <option value={d.id}>{d.name}</option>
                  })}
                </select>
              </div>

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Visit Date <span className="text-danger">*</span></label>
                <input type='datetime-local' value={visitDate} onChange={(e) => setVisitDate(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

            </div>
            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id={'closeAssignBtn' + data.id} style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleAssign}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Save"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------Mark Paid Modal---------------------------- */}

      <div class="modal fade" id={"markPaidModal" + data.id} tabindex="-1" aria-labelledby="markPaidModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="markPaidModalLabel">Mark Paid</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Company <span className="text-danger">*</span></label>
                <CreatableSelect options={companyOptions} styles={customStyles} value={{ value: payload2.company, label: payload2.company }} onChange={(e) => setPayload2({ ...payload2, company: e.value })} />
                {/* <input type='text' placeholder='Enter Company...' value={payload2.company} onChange={(e) => setPayload2({ ...payload2, company: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Type <span className="text-danger">*</span></label>
                <select value={payload2.type} onChange={(e) => setPayload2({ ...payload2, type: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="">----select type----</option>
                  <option value="F">Fresh</option>
                  <option value="M">Migration</option>
                  <option value="R">Renewal</option>
                  <option value="P">Port</option>
                </select>
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Product <span className="text-danger">*</span></label>
                <CreatableSelect options={productOptions} styles={customStyles} value={{ value: payload2.product, label: payload2.product }} onChange={(e) => setPayload2({ ...payload2, product: e.value })} />
                {/* <input type='text' value={payload2.product} onChange={(e) => setPayload2({ ...payload2, product: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Medical/Non-Medical <span className="text-danger">*</span></label>
                <select value={payload2.medical} onChange={(e) => setPayload2({ ...payload2, medical: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="">----select medical----</option>
                  <option value="Medical">Medical</option>
                  <option value="Non-Medical">Non-Medical</option>
                </select>
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Sum Assured <span className="text-danger">*</span></label>
                <CreatableSelect options={sumOptions} styles={customStyles} value={{ value: payload2.sum_assured, label: payload2.sum_assured }} onChange={(e) => setPayload2({ ...payload2, sum_assured: e.value })} />
                {/* <input type='text' value={payload2.sum_assured} onChange={(e) => setPayload2({ ...payload2, sum_assured: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Plan <span className="text-danger">*</span></label>
                <CreatableSelect options={planOptions} styles={customStyles} value={{ value: payload2.plan, label: payload2.plan }} onChange={(e) => setPayload2({ ...payload2, plan: e.value })} />
                {/* <input type='text' value={payload2.plan} onChange={(e) => setPayload2({ ...payload2, plan: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' /> */}
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Gross Premium <span className="text-danger">*</span></label>
                <input type='text' value={payload2.premium} onChange={(e) => setPayload2({ ...payload2, premium: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-1'>
                <label className='modal_label' style={{ color: color.darkText }}>Payment Mode <span className="text-danger">*</span></label>
                <select value={payload2.payment} onChange={(e) => setPayload2({ ...payload2, payment: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="ONLINE">ONLINE</option>
                  <option value="CHEQUE">CHEQUE</option>
                </select>
              </div>
            </div>
            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id={'closeAssignBtn2' + data.id} style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleMarkPaid}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Save"}</button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default RenewDataList