import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLeadServiceStore = create((set) => ({
    leadServices: [],
    fetchLeadServices: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-leads-services/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ leadServices: data })
    },
    updateDoneCompliance: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/done-compliance/'+id+'/',{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateLeadServices: async (alLeadServices, payload) => {
        let items = [...alLeadServices];
        var foundIndex = items.findIndex(x => x.id === payload.id);
        items[foundIndex] = payload
        set({ leadServices: items });
    },
}));