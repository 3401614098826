import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import PrList from './PrList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import * as XLSX from "xlsx";
import { useLotsizeStore } from '../../../Store/LotsizeStore/LotsizeStore';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import { usePrStore } from '../../../Store/PrStore/PrStore';

const Pr = () => {
    const { addToast } = useToasts();
    const { pr, fetchPr, createPr, setPr } = usePrStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({
        stock_name: '',
        pr: '',
        exp: '',
    })

    const [error, setError] = useState(false)
    const [error2, setError2] = useState(false)

    const handleSubmit = () => {

    }

    const [file, setFile] = useState()
    const handleImport = () => {
        if (!file) {
            setError2(true)
        } else {
            setError2(false)
            setIsLoading(true)
            let data = new FormData()
            data.append('f',file)
            createPr(data)
                .then(res => {
                    fetch()
                    setFile()
                    setIsLoading(false)
                    let d = document.getElementById('mclosebtn')
                    d.click()
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const fetch = () => {
        setIsLoading(true)
        fetchPr()
            .then(res => {
                setIsLoading(false)
                setPr(res)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }
    useEffect(() => {
        fetch()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["insured_name", "prev_policy_number", "policy_number"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem] ? item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                        : null
                );
            });
        });
    }

    let alist = [{
        system_issue_date: '',
        inward_date: '',
        quote: '',
        prev_policy_number: '',
        transaction_type: '',
        product_code: '',
        product_name: '',
        insured_name: '',
        address: '',
        policy_number: '',
        policy_start_date: '',
        policy_end_date: '',
        endorsement: '',
        endorsement_type: '',
        endorsement_reason: '',
        policy_type: '',
        sum_assured: '',
        net_premium: '',
        gross_premium: '',
        payment_mode: '',
        instrument_number: '',
        bank_name: '',
        branch_name: '',
    }]

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(pr).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(pr).length / itemsPerPage));
            setitemLength(search(pr).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(pr.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(pr.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(pr.length)
        }

    }, [itemOffset, itemsPerPage, pr, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % pr.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' className='mx-1' during={1000}>
                            <button className="successButton" data-bs-toggle="modal" data-bs-target="#lotsizeImportModal" >Import</button>
                        </Ripples>

                        <Ripples color='rgba(255,255,255,0.2)' className='mx-1' during={1000}>
                            <CSVLink data={pr} filename='pr.csv'><button className="warningButton left_border_radius"><Icon icon="ph:export-fill" fontSize={16} /> Export</button></CSVLink>
                        </Ripples>
                    </div>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle mb-0 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">System Issue Date</th>
                                <th style={{ color: color.darkText }} scope="col">Inward Date</th>
                                <th style={{ color: color.darkText }} scope="col">Quote No</th>
                                <th style={{ color: color.darkText }} scope="col">Previous Policy number</th>
                                <th style={{ color: color.darkText }} scope="col">Transaction Type</th>
                                <th style={{ color: color.darkText }} scope="col">Product Code</th>
                                <th style={{ color: color.darkText }} scope="col">Product Name</th>
                                <th style={{ color: color.darkText }} scope="col">Insured Name</th>
                                <th style={{ color: color.darkText }} scope="col">Address</th>
                                <th style={{ color: color.darkText }} scope="col">Policy No</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Start Date</th>
                                <th style={{ color: color.darkText }} scope="col">Policy End Date</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement No</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement Type</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement Reason</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Type</th>
                                <th style={{ color: color.darkText }} scope="col">Total Sum Assured</th>
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Gross Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Mode of Payment</th>
                                <th style={{ color: color.darkText }} scope="col">Instrument No</th>
                                <th style={{ color: color.darkText }} scope="col">Bank Name</th>
                                <th style={{ color: color.darkText }} scope="col">Branch Name</th>
                                <th style={{ color: color.darkText }} scope="col">Created At</th>

                                {/* <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {pr.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <PrList data={c} key={c.id} count={itemOffset+count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>

                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px', marginTop: '5px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

         
            {/* ----------------------Import Modal---------------------------- */}

            <div class="modal fade" id="lotsizeImportModal" tabindex="-1" aria-labelledby="lotsizeImportModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="lotsizeImportModalLabel">Import PR</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error2 && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please Select CSV File...!</div>}

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach File (.csv,.xlsx) <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {<CSVLink data={alist} filename={"sample.csv"}><div className='text-start'> <span style={{ fontSize: '14px', color: color.blue2 }}>Click here to Download CSV Format... </span></div></CSVLink>}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='mclosebtn' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleImport}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Import"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pr