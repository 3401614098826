import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../../../Loading';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { useColorStore } from '../../../../../Store/ColorStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { userSegrigation, userType } from '../../../../../Export';
import { useUserPermissionStore } from '../../../../../Store/UserPermissionStore/UserPermissionStore';
import { leadFollowUpOptions } from '../../../../../Export';
import { useMisStore } from '../../../../../Store/MisStore/MisStore';
import { Creatable } from 'react-select';
import { useLeadActionStore } from './../../../../../Store/LeadStore/LeadAction';
import { useUserStore } from './../../../../../Store/UserStore/UserStore';
const ServiceList = React.lazy(() => { return import('./ServiceList') });
const animatedComponents = makeAnimated();

const Services = (props) => {
    const { leadEnquiry } = props
    const [mis, setMis] = useState([])
    const [misOther, setMisOther] = useState()
    let token = JSON.parse(localStorage.getItem('user'))
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { fetchMisEnquiry} = useMisStore()
    const { restoreLeads, trashLeads, assignLeads } = useLeadActionStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    // ------------------------------------------Permission Work---------------------------------------

    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)

    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'LEADS_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'OWNER_CHANGE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])

    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState([])
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    // -------Options-----------
    const [options, setOptions] = useState([])

    const leadServiceStateOptions = [
        { value: 'P', label: 'Pending' },
        { value: 'C', label: 'Completed' },
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        let us = users.filter(l => l.user_type === userType.sales_executive)
        for (let i = 0; i < us.length; i++) {
            const u = us[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus([
            ...status.slice(0, index),
            ...status.slice(index + 1, status.length)
        ]);
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nowner = filterParams.get('owner');
            const nfilterServiceState = filterParams.get('filterServiceState');
            const nstatus = filterParams.get('status');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nsearch = filterParams.get('search');
            setStatus(leadFollowUpOptions.filter((f) => {
                return nstatus.split(',').indexOf(f.value) > -1;
            }))
            setOwner(options.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setFilterServiceState(leadServiceStateOptions.filter((f) => {
                return nfilterServiceState.split(',').indexOf(f.value) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterServiceState.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            navigate(`${u}`);
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterServiceState([])
        setStatus([])
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        navigate('')
        setFilterMode(false)
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? mis.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (misOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(misOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(misOther.count)
        }
    }, [misOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % misOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************


    // *************************************************Main Url Work****************************************************

    useEffect(() => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterServiceState=${filterServiceState.map((f) => { return f.value })}&status=${status.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
        setIsLoading(true)
        fetchMisEnquiry(u,leadEnquiry.id)
            .then(res => {
                setIsLoading(false)
                setMis(res.data)
                setMisOther({count: Number(res.data.count), currentPage: Number(res.data.currentPage)})
            })
            .catch(err => {
                setIsLoading(false)
            })
    }, [reload, itemsPerPage, currentPage, filterToggle])

    // ******************************************************************************************************

    return (
        <>

            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                </div>

                <div className="table-responsive mt-3 table-bordered table-striped">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {misOther ? misOther.count : 0}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3" style={{ width: '1500px' }}>
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Company</th>
                                <th style={{ color: color.darkText }} scope="col">Type</th>
                                <th style={{ color: color.darkText }} scope="col">Month</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Duration</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Status</th>
                                <th style={{ color: color.darkText }} scope="col">Source By</th>
                                <th style={{ color: color.darkText }} scope="col">Pick up By</th>
                                <th style={{ color: color.darkText }} scope="col">Sourcing Date</th>
                                <th style={{ color: color.darkText }} scope="col">Login Date</th>
                                <th style={{ color: color.darkText }} scope="col">Proposer</th>
                                <th style={{ color: color.darkText }} scope="col">Insured Person</th>
                                <th style={{ color: color.darkText }} scope="col">Contact</th>
                                <th style={{ color: color.darkText }} scope="col">Email</th>
                                <th style={{ color: color.darkText }} scope="col">Alternate Contact</th>
                                <th style={{ color: color.darkText }} scope="col">Address</th>
                                <th style={{ color: color.darkText }} scope="col">Product</th>
                                <th style={{ color: color.darkText }} scope="col">Medical/Non-Medical</th>
                                <th style={{ color: color.darkText }} scope="col">Sum Assured</th>
                                <th style={{ color: color.darkText }} scope="col">Plan</th>
                                <th style={{ color: color.darkText }} scope="col">Previous Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Gross Premium</th>
                                <th style={{ color: color.darkText }} scope="col">GST</th>
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Payment Mode</th>
                                <th style={{ color: color.darkText }} scope="col">Bank Name</th>
                                <th style={{ color: color.darkText }} scope="col">Cheque Number & Date</th>
                                <th style={{ color: color.darkText }} scope="col">Discount</th>
                                <th style={{ color: color.darkText }} scope="col">Qoute No</th>
                            </tr>
                        </thead>

                        <tbody>
                            {mis.length > 0 ?
                                mis.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <ServiceList checked={checked} users={users} handleSingleChange={handleSingleChange} reload={reload} setReload={setReload} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {mis.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default Services