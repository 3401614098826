import React, { useState, useEffect } from 'react'
import './Sidebar.css'
import logo from '../../../Images/moneybugs.png'
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '@iconify/react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Ripples from 'react-ripples'
import { useColorStore } from '../../../Store/ColorStore';
import { Link, useLocation } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { fileUrl, mediaUrl, userType } from '../../../Export';

const OperationSidebar = (props) => {
    const { user } = props
  let location = useLocation();
  const { color } = useColorStore()
  let token = JSON.parse(localStorage.getItem('user'))
  let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
  return (
    <div className="menuWrapper">
            <Scrollbars autoHide={true}
              renderThumbVertical={props => <div {...props} style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: '5px' }} />}
            >
              {/* ======================================================================Dashboard Block========================================================= */}
              <div>
                <div className="menu-title">Menu</div>

                <Ripples color={color.rippleColor} during={800}>
                  <Link to={AdminPath.DASHBOARD} className={location.pathname === AdminPath.DASHBOARD ? "menu-item menu-active" : "menu-item"}>
                    <div className=""><Icon icon="uim:layer-group" fontSize={17} />&nbsp; Dashboard</div>
                  </Link>
                </Ripples>

                <div id="accordionExample">

                  <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <div className={location.pathname === AdminPath.EMAIL || location.pathname === AdminPath.INBOX || location.pathname === AdminPath.SENTMAIL || location.pathname === AdminPath.TRASH || location.pathname === AdminPath.STARRED ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className="d-flex align_center"><Icon icon="fluent:mail-48-filled" fontSize={18} />&nbsp; Email</div>
                      <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
                    </div>
                  </Ripples>

                  <div id="collapseOne" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">
                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.INBOX} className={location.pathname === AdminPath.INBOX ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="mingcute:inbox-fill" fontSize={18} />&nbsp; Inbox</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.SENTMAIL} className={location.pathname === AdminPath.SENTMAIL ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="ic:round-mark-email-read" fontSize={18} />&nbsp; Sent Mail</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.TRASH} className={location.pathname === AdminPath.TRASH ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="basil:trash-solid" fontSize={18} />&nbsp; Trash</div>
                      </Link>
                    </Ripples>

                  </div>
                </div>
              </div>
              {/* ======================================================================Research Calculator Block========================================================= */}
              {/* ======================================================================Operations Block========================================================= */}

                  <div>
                    <div className="menu-title">Operations</div>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.SOURCELEADS} className={location.pathname === AdminPath.SOURCELEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="solar:user-bold-duotone" fontSize={18} />&nbsp; Source Leads</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.MIS} className={location.pathname === AdminPath.MIS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="material-symbols:mist" fontSize={18} />&nbsp; Sourcing</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.PR} className={location.pathname === AdminPath.PR ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="ri:git-pr-draft-line" fontSize={18} />&nbsp; PR</div>
                      </Link>
                    </Ripples>

                  </div>
                 
            </Scrollbars>
          </div >
  )
}

export default OperationSidebar