import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useFieldLeadStore = create((set) => ({
    fieldLeads: [], fieldLeadsOther: null,
    fetchFieldLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/fetch-field-enquiry/' + supervisor + '/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchFieldLeadsOperations: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-field-enquiry-operations/?' + payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchFieldLeadsFiles: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-field-enquiry-files/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    deleteSourceLead: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-source-lead/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setFieldLeads: async (data) => {
        set({ fieldLeads: data.data, fieldLeadsOther: { count: Number(data.count), currentPage: Number(data.currentPage) } })
    },
    assignFieldLead: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/assign-field-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    reAssignFieldLead: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/reassign-field-enquiry/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    addFollowUpFieldLead: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/add-followup-field-enquiry/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    deleteFollowUpFieldLead: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-followup-field-enquiry/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    doneFollowUpFieldLead: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/done-followup-field-enquiry/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
}));