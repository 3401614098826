import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useResearchProductStore = create((set) => ({
    researchProduct: [],
    fetchResearchProduct: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'research/view-research-product/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ researchProduct: data });
    },
    createResearchProduct: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/create-research-product/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateResearchProduct: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'research/update-research-product/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteResearchProduct: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'research/delete-research-product/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));