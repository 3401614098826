import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useIpAddressStore } from '../../../../Store/SpaceStore/IpAdressStore';
import { useToasts } from 'react-toast-notifications';
import { fileUrl } from '../../../../Export';

const IpAddressList = (props) => {
    const { addToast } = useToasts();
    const { deleteIpAddress, fetchIpAddress } = useIpAddressStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props
    
       
    const handleDelete = () => {
        let d = document.getElementById("dltBtn"+data.id)
        d.click()
        setIsLoading(true)
        deleteIpAddress(data.id)
            .then(res => {
                fetchIpAddress()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const handleSave = ()=>{
        fetch(fileUrl+data.pdf_file).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = data.filename;
                alink.click();
            })
        })
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {/* <div class="">
                        <input type="checkbox" className="form-check-input" style={{backgroundColor:color.inputBackground,border:"1px solid "+color.borderColor}} />
                    </div> */}
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    <a style={{ color: color.darkText }}>{data.ip}</a>
                </td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.ip} ) IP Address ? You would'nt be able to retrieve this.</div>
                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtn"+data.id}style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default IpAddressList