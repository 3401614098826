import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useComplaintStore = create((set) => ({
    complaint: [],
    fetchComplaint: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-complaint/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ complaint: data });
    },
    createComplaint: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/create-complaint/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateComplaint: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/update-complaint/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteComplaint: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-complaint/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));