import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'
import { useUserCompanyAssetsStore } from './../../../../../Store/UserStore/CompanyAssetsStore';

const UserCompanyAssets = (props) => {
    const { addToast } = useToasts()
    const { color, isLoading, setIsLoading, currentUser,permission } = props
    const { users, fetchUsers } = useUserStore()
    const { usersCompanyAssets, fetchUsersCompanyAssets, createUsersCompanyAssets } = useUserCompanyAssetsStore()
    const [error, setError] = useState(false)

    const [payload, setPayload] = useState({
        user: currentUser.id,
        department: usersCompanyAssets.department,
        monitor: usersCompanyAssets.monitor,
        cpu: usersCompanyAssets.cpu,
        keyboard: usersCompanyAssets.keyboard,
        mouse: usersCompanyAssets.mouse,
        ups: usersCompanyAssets.ups,
        extension: usersCompanyAssets.extension,
        cc: usersCompanyAssets.cc,
        sim: usersCompanyAssets.sim,
        attendance_card: usersCompanyAssets.attendance_card,
        other: usersCompanyAssets.other,

    })

    const handleSubmit = () => {
        if (payload.department.length === 0) {
            setError(true)
        } else {
            setError(false)
            createUsersCompanyAssets(payload)
                .then(res => {
                    fetchUsersCompanyAssets(currentUser.id)
                    setIsLoading(false)
                    setPayload({
                        user: currentUser.id,
                        department: payload.department,
                        monitor: payload.monitor,
                        cpu: payload.cpu,
                        keyboard: payload.keyboard,
                        mouse: payload.mouse,
                        ups: payload.ups,
                        extension: payload.extension,
                        cc: payload.cc,
                        sim: payload.sim,
                        attendance_card: payload.attendance_card,
                        other: payload.other,
                    })
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }

    }

    useEffect(() => {
        if (usersCompanyAssets) {
            setPayload({
                ...payload,
                user: currentUser.id,
                department: usersCompanyAssets.department,
                monitor: usersCompanyAssets.monitor,
                cpu: usersCompanyAssets.cpu,
                keyboard: usersCompanyAssets.keyboard,
                mouse: usersCompanyAssets.mouse,
                ups: usersCompanyAssets.ups,
                extension: usersCompanyAssets.extension,
                cc: usersCompanyAssets.cc,
                sim: usersCompanyAssets.sim,
                attendance_card: usersCompanyAssets.attendance_card,
                other: usersCompanyAssets.other,
            })
        } else {
            setPayload({
                ...payload,
                user: currentUser.id,
                department: "",
                monitor: "",
                cpu: "",
                keyboard: "",
                mouse: "",
                ups: "",
                extension: "",
                cc: "",
                sim: "",
                attendance_card: "",
                other: "",
            })
        }
    }, [usersCompanyAssets])


    useEffect(() => {
        fetchUsersCompanyAssets(currentUser.id)
    }, [])


    return (
        <>
            <div className="mt-4">
                <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                    {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Department Required...!</div>}

                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Department <span className="text-danger">*</span></label>
                        <input type='text' placeholder='Enter department' value={payload.department} disabled={!permission} onChange={(e) => setPayload({ ...payload, department: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Monitor</label>
                        <input placeholder='About Monitor' value={payload.monitor} disabled={!permission} onChange={(e) => setPayload({ ...payload, monitor: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>CPU</label>
                        <input placeholder='Enter Cpu Name' value={payload.cpu} disabled={!permission} onChange={(e) => setPayload({ ...payload, cpu: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Keyboard </label>
                        <input placeholder='Enter keyboard' value={payload.keyboard} disabled={!permission} onChange={(e) => setPayload({ ...payload, keyboard: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Mouse </label>
                        <input placeholder='Enter...' value={payload.mouse} disabled={!permission} onChange={(e) => setPayload({ ...payload, mouse: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>UPS </label>
                        <input placeholder='Enter...' value={payload.ups} disabled={!permission} onChange={(e) => setPayload({ ...payload, ups: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>
                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Extension </label>
                        <input placeholder='Enter...' value={payload.extension} disabled={!permission} onChange={(e) => setPayload({ ...payload, extension: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>
                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>CC </label>
                        <input placeholder='Enter...' value={payload.cc} disabled={!permission} onChange={(e) => setPayload({ ...payload, cc: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>
                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>SIM </label>
                        <input placeholder='Enter...' value={payload.sim} disabled={!permission} onChange={(e) => setPayload({ ...payload, sim: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>
                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Attendance Card </label>
                        <input placeholder='Enter...' value={payload.attendance_card} disabled={!permission} onChange={(e) => setPayload({ ...payload, attendance_card: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>
                    
                    <div className='mb-2'>
                        <label className='modal_label' style={{ color: color.darkText }}>Other </label>
                        <input placeholder='Enter...' value={payload.other} disabled={!permission} onChange={(e) => setPayload({ ...payload, other: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                </div>

                {permission &&
                <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
                    <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                        <>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                            Please wait...
                        </>

                        : "Submit"}</button>
                </div>
}
            </div>
        </>
    )
}

export default UserCompanyAssets