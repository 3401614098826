import React, { useEffect, useState } from 'react'
import { useUserDocumentsStore } from '../../../../../Store/UserStore/UserDocumentStore'
import { useToasts } from 'react-toast-notifications'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { Icon } from '@iconify/react/dist/iconify.js'
import { fileUrl, mediaUrl } from '../../../../../Export'

const UserDocuments = (props) => {
    const { addToast } = useToasts()
    const { color, isLoading, setIsLoading, currentUser, permission, userId } = props
    const { users, fetchUsers } = useUserStore()
    const { userDocuments, fetchUsersDocument, createUsersDocument, deleteUsersDocument } = useUserDocumentsStore()
    const [file, setFile] = useState()
    const [filename, setFilename] = useState('')

    const handleUpload = () => {
        let formData = new FormData()
        formData.append('user', userId)
        formData.append('doc', file)
        formData.append('filename', filename)
        let d = document.getElementById("ChangeDocumentClose")
        d.click()
        setIsLoading(true)
        createUsersDocument(formData)
            .then(res => {
                addToast('Success...', { appearance: 'success' });
                setIsLoading(false)
                setFilename('')
                setFile()
                fetchUsersDocument(userId)
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    useEffect(() => {
        fetchUsersDocument(userId)
    }, [])
    
    const handleSave = (file, name) => {
        let alink = document.createElement('a');
        alink.href = fileUrl+file;
        alink.download = name;
        alink.target = 'blank';
        alink.click();
    }

    const handleDelete = (id)=>{
        setIsLoading(true)
        deleteUsersDocument(id)
        .then(res=>{
            setIsLoading(false)
            fetchUsersDocument(userId)
            addToast('Deleted...', { appearance: 'success' });
        })
        .catch(err=>{
            setIsLoading(false)
            addToast('Error...', { appearance: 'error' });
        })
    }

    return (
        <>
            <div className="text-end">
                <button className="infoButton" data-bs-toggle="modal" data-bs-target="#uploadUserDocumentModal" >Upload</button>
            </div>

            <div className="mt-0">
                    <div className="myrow">
                        {userDocuments.map((file) => {
                            return <div className="mycard" key={file.id} style={{ border: '0.5px solid ' + color.borderColor }}>
                                <button style={{ cursor: 'default' }} className="primaryButton py-2"><Icon icon="ph:file-text-fill" fontSize={50} /></button>
                                <div className="email_file_name" style={{ color: color.darkText }}>
                                    {file.filename}
                                </div>
                                <div className="text-center">
                                    <span onClick={() => handleSave(file.doc, file.filename)} className="email_download_link">
                                        View
                                    </span>
                                </div>
                                <div className="text-center">
                                    <span onClick={() => handleDelete(file.id)} className="email_download_link" style={{color:'tomato'}}>
                                        Delete
                                    </span>
                                </div>
                            </div>
                        })
                        }
                    </div>
                </div>


            {/* --------------Profile Pic Change Modal--------------------------- */}

            <div className="modal fade" id="uploadUserDocumentModal" tabInex="-1" aria-labelledby="changeProfilePicLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="changeProfilePicLabel">Upload Documents</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>File name <span className="text-danger">*</span></label>
                                <input type='text' value={filename} onChange={(e) => setFilename(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Upload Image <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='ChangeDocumentClose' style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleUpload}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Upload"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDocuments