import React from 'react'
import { useColorStore } from '../../../../Store/ColorStore';

const SelectUsersList = (props) => {
    const { color } = useColorStore()
    const { u, users, selectedUsers, setSelectedUsers } = props

    const handleSingleChange = (e, c) => {
        setSelectedUsers((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    return (<>
        <div style={{ width:'32%', color: color.darkText, fontSize: '13px', paddingRight: '5px', marginBottom: '10px', display: 'flex',alignItems:'center' }} className='usershow'>
            <input checked={selectedUsers.includes(u.id)} onChange={(e) => handleSingleChange(e, u)} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor, marginRight: '10px' }} />
            <div style={{marginTop:'5px'}}>{u.name} ({u.user_type})</div>
        </div>
        </> )
}

export default SelectUsersList