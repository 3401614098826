import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useResearchProductStore } from '../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';
import { callDetailsNumber, callDetailsWebsite } from '../../../Export';
import { DateTime } from 'luxon';
import { useResearchCallStore } from '../../../Store/ResearchStore/CallStore/CallStore';

const RecommendationList = (props) => {
    const { addToast } = useToasts();
    const { sendUpdate, fetchUpdates } = useResearchCallStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, product, users, updates } = props
    const [msgContent, setMsgContent] = useState('')
   
    const findUsers = (id) => {
        let data = users.find((f) => {
            return f.id === id
        })
        if (data) {
            return data.name
        } else { return '-' }
    }
    const findProduct = (id) => {
        let data = product.find((f) => {
            return f.id === id
        })
        if (data) {
            return data.name
        } else { return '-' }
    }

    const [fUpdates, setFUpdates] = useState([])
    useEffect(() => {
        let d = updates.filter((f) => {
            return f.call_id === data.id
        })
        setFUpdates(d)
    }, [updates])


    return (
        <>
            <tr style={{ color: color.darkText }}>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                   {findUsers(data.owner_id)}
                    <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '5px' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} />
                        <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    {findProduct(data.segmant_id)}
                    <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    </span>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, whiteSpace: 'pre-wrap' }}>
                    <small>{data.calls}</small>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, whiteSpace: 'pre-wrap' }}>
                    {fUpdates.map((d) => {
                        return <div key={d.id} className='mb-1'>
                            <small>{d.updates} <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '1px' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} />
                                <span style={{ marginLeft: '6px' }}>{moment(d.created_at).format('lll')}</span></span>
                            </small>
                        </div>
                    })}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder,width:'100px' }}>
                    {data.profit_loss ? Number(data.profit_loss) > 0 ? <span style={{ color: color.green }}>₹ {data.profit_loss}</span> : <span style={{ color: color.red }}>₹ {data.profit_loss}</span> : '-'}
                </td>
            </tr>

        </>
    )
}

export default RecommendationList