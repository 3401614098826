import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from '../../../Store/ColorStore';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { useLeadActionStore } from './../../../Store/LeadStore/LeadAction';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Months, userSegrigation, userType } from '../../../Export';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import { leadFollowUpOptions } from '../../../Export';
import { useFieldLeadStore } from '../../../Store/LeadStore/FieldLeadStore';
import { useMisStore } from '../../../Store/MisStore/MisStore';
import { usePrStore } from './../../../Store/PrStore/PrStore';
import { Creatable } from 'react-select';
import { AdminPath } from '../../Admin/AdminRoutes/AdminPath';
const SearchMisList = React.lazy(() => { return import('./SearchMisList') });
const animatedComponents = makeAnimated();


const SearchMis = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { fetchSearchMis, searchMis, setSearchMis, searchMisOther } = useMisStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [searchInput, setSearchInput] = useState("")

    function search() {
        setReload(!reload)
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    // ------------------------------------------Permission Work---------------------------------------

    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)

    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'LEADS_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'OWNER_CHANGE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])

    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? searchMis.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchMisOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(searchMisOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(searchMisOther.count)
        }
    }, [searchMisOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % searchMisOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************


    // *************************************************Main Url Work****************************************************

    useEffect(() => {
        setIsLoading(true)
        fetchSearchMis(id)
            .then(res => {
                setIsLoading(false)
                setSearchMis(res)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }, [reload, itemsPerPage, currentPage])



    return (
        <>

            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className='myrow align_center'>
                    <Link to={AdminPath.DASHBOARD} className="primaryButton me-2">Back</Link>
                    {searchMis.length === 0 ? <div style={{ color: color.red, fontSize: 12}}>No search result found.</div>
                        :
                        <div style={{ color: color.darkText, fontSize: 12}}>{searchMis.length} search result found.</div>
                    }
                </div>
                <div className="table-responsive mt-3">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        &nbsp; Out of - {searchMisOther ? searchMisOther.count : 0}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3 table-bordered table-striped" style={{ width: '1500px' }}>
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Category</th>
                                <th style={{ color: color.darkText }} scope="col">Company</th>
                                <th style={{ color: color.darkText }} scope="col">Type</th>
                                <th style={{ color: color.darkText }} scope="col">Proposer</th>
                                <th style={{ color: color.darkText }} scope="col">Insured Person</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Duration</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Status</th>
                                <th style={{ color: color.darkText }} scope="col">Source By</th>
                                <th style={{ color: color.darkText }} scope="col">Pick up By</th>
                                <th style={{ color: color.darkText }} scope="col">Sourcing Date</th>
                                <th style={{ color: color.darkText }} scope="col">Login Date</th>
                                <th style={{ color: color.darkText }} scope="col">Alternate Contact</th>
                                <th style={{ color: color.darkText, minWidth: '150px' }} scope="col">Address</th>
                                <th style={{ color: color.darkText }} scope="col">Product</th>
                                <th style={{ color: color.darkText }} scope="col">Medical/Non-Medical</th>
                                <th style={{ color: color.darkText }} scope="col">Sum Assured</th>
                                <th style={{ color: color.darkText }} scope="col">Plan</th>
                                <th style={{ color: color.darkText }} scope="col">Previous Policy Number</th>
                                <th style={{ color: color.darkText }} scope="col">Gross Premium</th>
                                <th style={{ color: color.darkText }} scope="col">GST</th>
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Payment Mode</th>
                                <th style={{ color: color.darkText }} scope="col">Bank Name</th>
                                <th style={{ color: color.darkText }} scope="col">Cheque Number & Date</th>
                                <th style={{ color: color.darkText }} scope="col">Discount</th>
                                <th style={{ color: color.darkText }} scope="col">Qoute No</th>
                                <th style={{ color: color.darkText }} scope="col">Policy File</th>
                            </tr>
                        </thead>

                        <tbody>
                            {searchMis.length > 0 ?
                                searchMis.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <SearchMisList checked={checked} users={users} handleSingleChange={handleSingleChange} reload={reload} setReload={setReload} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {searchMis.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default SearchMis