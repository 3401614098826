import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useUserJoiningInfoStore = create((set) => ({
    usersJoiningInfo: {},
    fetchUsersJoiningInfo: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-user-joining-details/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ usersJoiningInfo: data });
    },
    createUsersJoiningInfo: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-user-joining-details/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));