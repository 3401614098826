import React from 'react'
import { useColorStore } from './Store/ColorStore'

const Loading = () => {
    const {color} = useColorStore()
  return (
    <div className="overlay" style={{backgroundColor:color.darkText}}></div>
  )
}

export default Loading