import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const usePrStore = create((set) => ({
    pr: [],
    fetchPr: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-pr/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setPr: async (data) => {
        set({ pr: data });
    },
    createPr: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/create-pr/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updatePr: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-pr/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    deletePr: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-pr/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    }
}));