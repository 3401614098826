import React from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../Store/ColorStore';
import { Icon } from '@iconify/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useEmailStore } from '../../Store/EmailStore/EmailStore';
import { Suspense } from 'react';
import { useState, useEffect, useRef } from 'react';
import Loading from '../../Loading'
import { useToasts } from 'react-toast-notifications';
import { Avatar } from '@mui/material';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from './../../Store/UserStore/UserStore';
import { fileUrl, mediaUrl, tinyApiKey } from '../../Export';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import EmailReply from './EmailReply';

const CurrentEmail = () => {
    const navigation = useNavigate()
    const editorRef = useRef(null);
    let token = JSON.parse(localStorage.getItem('user'))
    const { uid } = useParams()
    const { addToast } = useToasts()
    const { fetchEmailFile, trashEmail, createEmailReply, fetchEmailReply, labelEmail, fetchCurrentEmail, starredSingleEmail, emailList, updateEmailList } = useEmailStore()
    const { color } = useColorStore()
    const [isLoading, setIsLoading] = useState(false)
    const { users, fetchUsers } = useUserStore()

    const [currentEmail, setCurrentEmail] = useState()
    const [currentEmailList, setCurrentEmailList] = useState()
    const [receiverList, setReceiverList] = useState([])

    const [pageRefresh, setPageRefresh] = useState(false)
    const pageHandler = () => {
        setPageRefresh(!pageRefresh)
    }

    useEffect(() => {
        setIsLoading(true)
        fetchCurrentEmail(uid)
            .then(res => {
                setIsLoading(false)
                setCurrentEmail(res.data)
                setCurrentEmailList(res.list)
                setReceiverList(res.userlist)
            })
            .catch(err => {
                setIsLoading(false)
                addToast('No email Found...!', { appearance: 'error' });
            })
    }, [uid, pageRefresh])

    const [showDropDownNotification, setShowDropDownNotification] = useState(false)
    document.onclick = function () {
        setShowDropDownNotification(false)
    }

    const [ids, setIds] = useState([])
    useEffect(() => {
        if (currentEmailList) {
            setIds([currentEmailList.id])
        }

    }, [currentEmailList])

    const handleLabelEmail = (data) => {
        if (ids.length > 0) {
            let newData = {
                label: data,
                ids: ids
            }
            labelEmail(newData)
                .then(res => {
                    setIsLoading(false)
                    pageHandler()
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        } else {
            addToast('Error...', { appearance: 'error' });
        }
    }

    const handleStarredSubmit = () => {
        if (ids.length > 0) {
            setIsLoading(true)
            starredSingleEmail(currentEmailList.id)
                .then(res => {
                    updateEmailList(emailList, res.data)
                    setIsLoading(false)
                    pageHandler()
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    addToast('Error...', { appearance: 'error' });
                })
        } else {
            addToast('Error...', { appearance: 'error' });
        }
    }

    const handleTrashSubmit = () => {
        if (ids.length > 0) {
            trashEmail(ids)
                .then(res => {
                    setIsLoading(false)
                    pageHandler()
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        } else {
            addToast('Please select email...', { appearance: 'error' });
        }
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [users])

    const [mailer, setMailer] = useState()

    useEffect(() => {

        if (users.length > 0 && currentEmailList) {
            let f = users.find((f) => {
                return f.id === currentEmailList.sender_id
            })
            if (f) {
                setMailer(f)
            }
        }
    }, [users, currentEmailList])

    const [reciepentList, setReciepentList] = useState([])

    useEffect(() => {
        if (users.length > 0 && receiverList.length > 0) {
            let d = users.filter((e) => {
                return receiverList.indexOf(e.id) > -1;
            });
            if (d) {
                setReciepentList(d)
            }
        }
    }, [users, receiverList])

    const [emailFiles, setEmailFiles] = useState([])

    useEffect(() => {
        if (currentEmail) {
            fetchEmailFile(currentEmail.id)
                .then(res => {
                    setEmailFiles(res)
                })
        }

    }, [currentEmail])

    const handleSave = (file, name) => {
        let alink = document.createElement('a');
        alink.href = mediaUrl+file;
        alink.download = name;
        alink.target = 'blank';
        alink.click();
    }

    const [ico_color, setico_color] = useState(color.blue)

    useEffect(() => {
        if (currentEmailList) {
            if (currentEmailList.label) {
                if (currentEmailList.label === 'Client Invoice') {
                    setico_color(color.blue2)
                }
                else if (currentEmailList.label === 'Edit Enquiry') {
                    setico_color(color.yellow)
                }
                else if (currentEmailList.label === 'Regarding Services') {
                    setico_color(color.blue)
                }
                else if (currentEmailList.label === 'EOD') {
                    setico_color(color.green)
                }
            }
        }
    }, [currentEmailList])

    const [iamSender, setIamSender] = useState(false)

    useEffect(() => {
        if (currentEmailList) {
            if (currentEmailList.receiver_id === token.id) {
                setIamSender(false)
            } else {
                setIamSender(true)
            }
        }
    }, [currentEmailList, token])

    // ---------------------------------Reply Work-----------------------------------------

    const [content, setContent] = useState()
    const [error, setError] = useState(false)
    const [reply, setReply] = useState([])

    const handleEmailReply = () => {
        fetchEmailReply(currentEmail.id)
            .then(res => {
                setReply(res.data)
            })
    }

    useEffect(() => {
        if (currentEmail) {
            handleEmailReply()
        }
    }, [currentEmail])

    const handleSubmit = () => {
        if (!content) {
            setError(true)
        }
        else {
            setError(false)
            setIsLoading(true)
            let data = {
                email: currentEmail.id,
                owner: token.id,
                mail: content
            }
            createEmailReply(data)
                .then(res => {
                    setContent()
                    handleEmailReply()
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    return (
        <>
            {currentEmail && currentEmailList ? <>
                <div className="myrow justify_space_between mb-4">
                    <div className="d-flex align_center">
                        <Icon icon="fluent:arrow-left-12-filled" className='modal_close' color={color.darkText} type='button' onClick={() => navigation(-1)} /> &nbsp;&nbsp;
                        <h6 style={{ color: color.darkText, marginTop: '5px' }}>Sender,</h6>
                    </div>

                    {!iamSender ?
                        <div className="myrow">
                            <Ripples color='rgba(255,255,255,0.2)' className='' during={650}>
                                <button onClick={handleStarredSubmit} className="primaryButton right_border_radius " style={{ marginRight: '0.7px' }}><Icon icon={currentEmailList.starred ? "mdi:star" : "mdi:star-outline"} fontSize={18} /></button>
                            </Ripples>

                            <Ripples color='rgba(255,255,255,0.2)' during={650}>
                                <button onClick={handleTrashSubmit} className="primaryButton left_border_radius right_border_radius" style={{ marginRight: '0.7px' }} ><Icon icon="basil:trash-solid" fontSize={18} /></button>
                            </Ripples>

                            <Ripples color='rgba(255,255,255,0.2)' className='' during={650}>
                                <button onClick={() => setShowDropDownNotification(!showDropDownNotification)} className="primaryButton left_border_radius"><Icon icon="material-symbols:label" fontSize={18} /></button>
                            </Ripples>
                            {showDropDownNotification &&
                                <div className="more_block" style={{ backgroundColor: color.secondaryBg, marginTop: '150px', width: 'fit-content', marginRight: '40px' }} onClick={(e) => e.stopPropagation()}>
                                    <div className="p-3">
                                        <div className="myrow justify_space_between mt-1">
                                            <h6 style={{ color: color.darkText, fontSize: '14px' }}>Labels</h6>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Client Invoice')}>
                                            <Icon icon="ph:circle-duotone" color={color.blue2} fontSize={15} style={{ marginTop: '-4px' }} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Client Invoice</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Edit Enquiry')}>
                                            <Icon icon="ph:circle-duotone" color={color.yellow} fontSize={15} style={{ marginTop: '-4px' }} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Edit Enquiry</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Regarding Services')}>
                                            <Icon icon="ph:circle-duotone" color={color.blue} fontSize={15} style={{ marginTop: '-4px' }} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Regarding Services</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('EOD')}>
                                            <Icon icon="ph:circle-duotone" color={color.green} fontSize={15} style={{ marginTop: '-4px' }} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>EOD</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: '-10px' }}>
                                        <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail(null)}>
                                            <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '-4px' }} />
                                            <div style={{ marginLeft: '10px' }}>
                                                <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Clear All</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                        </div>
                        : null}
                </div >

                <div className="mt-1">
                    {mailer ?
                        <div className="myrow">
                            <div className='mt-1'><Avatar sx={{ bgcolor: "#0C768A", color: "#fff", fontSize: 14, fontWeight: 600, width: '40px', height: '40px' }} src={fileUrl + mailer.proimg}>R</Avatar></div>
                            <div className='ps-3 pt-1'>
                                <div className="reportingTitle" style={{ color: color.darkText }}>
                                    {mailer.name}
                                </div>
                                <div className=" mb-0" style={{ color: color.lightText, fontSize: '13px' }}>{mailer.email}</div>
                                <div style={{ marginTop: '-3px' }}>
                                    <span className=" mb-0" style={{ color: color.lightText, fontSize: '13px' }}>To : </span>
                                    {reciepentList.map((m) => {
                                        return <span className="mb-0" key={m.id} style={{ color: color.lightText, fontSize: '13px' }}>{m.email}, </span>
                                    })}
                                </div>
                                <div style={{ marginTop: '-5px' }}>
                                    <span className=" mb-0" style={{ color: color.lightText, fontSize: '12px' }}>{moment(currentEmail.created_at).format("MMM Do, YY")} {moment(currentEmail.created_at).format("LT")}</span>
                                    {currentEmailList.label ?
                                        <div><Icon icon="bx:label" fontSize={18} color={color.lightText} /> <span className="emailListLabel" style={{ backgroundColor: ico_color }}>{currentEmailList.label}</span> </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null}
                </div>

                <div className='ms-1 p-1 pb-2'>
                    <h6 className='mt-5 mb-2' style={{ color: color.darkText }}>{currentEmail.subject}</h6>
                    <div style={{ color: color.lightText, fontSize: '13px' }}>
                        {parse(String(currentEmail.mail))}
                    </div>
                </div>


                <div className="mt-0">
                    <div className="myrow">
                        {emailFiles.map((file) => {
                            return <div className="mycard" key={file.id} style={{ border: '0.5px solid ' + color.borderColor }}>
                                <button style={{ cursor: 'default' }} className="primaryButton py-2"><Icon icon="ph:file-text-fill" fontSize={50} /></button>
                                <div className="email_file_name" style={{ color: color.darkText }}>
                                    {file.filename}
                                </div>
                                <div className="text-center">
                                    <span onClick={() => handleSave(file.file, file.filename)} className="email_download_link">
                                        Download
                                    </span>
                                </div>
                            </div>
                        })
                        }
                    </div>
                </div>

                <div className="mt-3 ms-2">
                    <button className="secondaryButton" data-bs-toggle="modal" data-bs-target="#emailReplyModal"><Icon icon="ic:baseline-reply" fontSize={18} /> Reply</button>
                </div>
            </>
                :
                <div style={{ color: color.darkText, fontSize: '14px' }}>No mail found...!</div>
            }

            {reply.length > 0 ?
                reply.map((r) => {
                    return <div key={r.id} className='ms-4 mt-4'>
                        <EmailReply data={r} users={users} />
                    </div>
                })
                : null}

            {isLoading && <Loading />}


            <div className="modal fade" id="emailReplyModal" tabIndex="-1" aria-labelledby="emailReplyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="emailReplyModalLabel">Mail Reply</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className="mb-3">
                                <label className='modal_label' style={{ color: color.darkText }}>Reply <span className="text-danger">*</span></label>
                                <Editor
                                    apiKey={tinyApiKey}
                                    onChange={(e) => setContent((editorRef.current.getContent()))}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    // value={content}
                                    init={{
                                        height: 300,
                                        menubar: true,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                />
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Reply"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CurrentEmail