import React, { useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../Store/ColorStore';
import { Icon } from '@iconify/react';
import Email from './Email';
import { Scrollbars } from 'react-custom-scrollbars';
import { useEmailStore } from '../../Store/EmailStore/EmailStore';
import { Suspense } from 'react';
import { useState } from 'react';
import Loading from '../../Loading'
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';

const EmailList = React.lazy(() => import('./EmailList'));

const Inbox = () => {
  const { addToast } = useToasts()
  const { email, fetchEmail, emailList, trashEmail, starredEmail, labelEmail } = useEmailStore()
  const { color } = useColorStore()
  const [ids, setIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleTrashSubmit = () => {
    if (ids.length > 0) {
      trashEmail(ids)
        .then(res => {
          setIds([])
          fetchEmail()
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Something went wrong...', { appearance: 'error' });
        })
    } else {
      addToast('Please select email...', { appearance: 'error' });
    }
  }

  const handleStarredSubmit = () => {
    if (ids.length > 0) {
      starredEmail(ids)
        .then(res => {
          setIds([])
          fetchEmail()
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Something went wrong...', { appearance: 'error' });
        })
    } else {
      addToast('Please select email...', { appearance: 'error' });
    }
  }

  // -------------------Search Work--------------------------------
  const [searchInput, setSearchInput] = useState("")
  const [searchParam] = useState(["subject", "mail"]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchInput.toLowerCase()) > -1
        );
      });
    });
  }

  const [showDropDownNotification, setShowDropDownNotification] = useState(false)
  document.onclick = function () {
    setShowDropDownNotification(false)
  }

  const handleLabelEmail = (data) => {
    if (ids.length > 0) {
      let newData = {
        label: data,
        ids: ids
      }
      labelEmail(newData)
        .then(res => {
          setIds([])
          fetchEmail()
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Something went wrong...', { appearance: 'error' });
        })
    } else {
      addToast('Please select email...', { appearance: 'error' });
    }
  }

  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemEndSet, setItemEndSet] = useState(0)
  const [itemLength, setitemLength] = useState(0)

  useEffect(() => {
    if (searchInput.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(search(email).slice(itemOffset, endOffset));
      setPageCount(Math.ceil(search(email).length / itemsPerPage));
      setitemLength(search(email).length)
      setItemEndSet(endOffset)
    }
    else {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(email.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(email.length / itemsPerPage));
      setItemEndSet(endOffset)
      setitemLength(email.length)
    }
  }, [itemOffset, itemsPerPage, email, searchInput]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % email.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="myrow justify_space_between mb-4">
        <div className="ico-search" style={{ position: 'relative' }}>
          <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
          <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
        </div>
        <div className="myrow">
          <Ripples color='rgba(255,255,255,0.2)' className='' during={650}>
            <button onClick={handleStarredSubmit} className="primaryButton right_border_radius " style={{ marginRight: '1px' }}><Icon icon="mdi:star" fontSize={18} /></button>
          </Ripples>

          <Ripples color='rgba(255,255,255,0.2)' during={650}>
            <button onClick={handleTrashSubmit} className="primaryButton left_border_radius right_border_radius" style={{ marginRight: '0.7px' }} ><Icon icon="basil:trash-solid" fontSize={18} /></button>
          </Ripples>

          <Ripples color='rgba(255,255,255,0.2)' className='' during={650}>
            <button onClick={() => setShowDropDownNotification(!showDropDownNotification)} className="primaryButton left_border_radius"><Icon icon="material-symbols:label" fontSize={18} /></button>
          </Ripples>
          {showDropDownNotification &&
            <div className="more_block" style={{ backgroundColor: color.secondaryBg, marginTop: '150px', width: 'fit-content', marginRight: '40px' }} onClick={(e) => e.stopPropagation()}>
              <div className="p-3">
                <div className="myrow justify_space_between mt-1">
                  <h6 style={{ color: color.darkText, fontSize: '14px' }}>Labels</h6>
                </div>
              </div>

              <div style={{ marginTop: '-10px' }}>
                <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Client Invoice')}>
                  <Icon icon="ph:circle-duotone" color={color.blue2} fontSize={15} style={{ marginTop: '-4px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Client Invoice</div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '-10px' }}>
                <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Edit Enquiry')}>
                  <Icon icon="ph:circle-duotone" color={color.yellow} fontSize={15} style={{ marginTop: '-4px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Edit Enquiry</div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '-10px' }}>
                <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('Regarding Services')}>
                  <Icon icon="ph:circle-duotone" color={color.blue} fontSize={15} style={{ marginTop: '-4px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Regarding Services</div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '-10px' }}>
                <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail('EOD')}>
                  <Icon icon="ph:circle-duotone" color={color.green} fontSize={15} style={{ marginTop: '-4px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>EOD</div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '-10px' }}>
                <div className="d-flex notification_wrapper align_center" onClick={() => handleLabelEmail(null)}>
                  <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '-4px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Clear All</div>
                  </div>
                </div>
              </div>

            </div>
          }

        </div>
      </div >
      <div style={{ height: '100vh' }}>

        {currentItems.length > 0 ?

          <Scrollbars autoHide={true}>

            {currentItems.map((e, count) => {
              return <Suspense key={e} fallback={<div></div>}>
                <EmailList data={e} ids={ids} setIds={setIds} isLoading={isLoading} list2={emailList} setIsLoading={setIsLoading} />
              </Suspense>
            })
            }

            {currentItems.length > 0 &&
              <div style={{ color: color.lightText, fontSize: '13px', marginTop: '20px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
            }

            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="pagination-active"
              renderOnZeroPageCount={null}
            />
          </Scrollbars>

          : <><div style={{ color: color.darkText, fontSize: '14px', textAlign: 'left' }}>No Record Found...!</div></>
        }
      </div>

      {isLoading && <Loading />}
    </>
  )
}

export default Inbox