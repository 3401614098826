import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from './../../../Store/ColorStore';
import { useAdminDashboardStore } from '../../../Store/DashboardStore.js/AdminDashboardStore';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import { numFormatter } from '../../../Export';
import PerformanceList from './../../Admin/Dashboard/PerformanceList/PerformanceList';
import Ripples from 'react-ripples';
import { Link, useNavigate } from 'react-router-dom';
import { AdminPath } from './../../Admin/AdminRoutes/AdminPath';
import moment from 'moment';

const Dashboard = () => {
  const navigate = useNavigate()
  const [today, setToday] = useState(moment().format('YYYY-MM-DD'))
  const [tommorow, setTommorow] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
  const [startdate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [endate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const { fetchTotals, fetchTotals2, totals2, totals, fetchBusinessOverview, fetchBusinessOverviewYear, fetchClientsOverview, business_overview, clients_overview, fetchUserPerformance, performance, dashLeadsData, fetchDashboardLeadsData, fetchDashboardSalesPerformance, salesPerformance } = useAdminDashboardStore()
  const { color } = useColorStore()
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);

  useEffect(() => {
    fetchTotals()
    fetchTotals2()
    fetchUserPerformance()
    // fetchClientsOverview()
    // fetchDashboardLeadsData()
    // fetchDashboardSalesPerformance()
  }, [])


  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    // const chart2 = echarts.init(chartRef2.current);

    // Sample data for the bar chart
    const data = business_overview;

    const option = {
      tooltip: {
        trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        text: '',
      },
      xAxis: {
        type: '',
        data: data.map(item => item.name),
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: [color.tableBorder, color.tableBorder]
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Issued Sourcing',
        data: data.map(item => item.value),
        showBackground: true,
        itemStyle: {
          color: color.blue,
        },
        backgroundStyle: {
          color: color.tableBorder,
        },
        barWidth: 16,
      },
      {
        type: 'bar',
        name: 'Total Sourcing',
        data: data.map(item => item.value2),
        showBackground: true,
        itemStyle: {
          color: color.red,
        },
        backgroundStyle: {
          color: color.tableBorder,
        },
        barWidth: 16,
      },
    ],
    };

    // Pie Chart configuration
    const pieOptions = {
      tooltip: {
        show: false
      },
      legend: {
        bottom: '10%',
        left: 'center', color: color.darkText, selectedMode: true,
        textStyle: {
          color: color.lightText
        }
      },
      series: [
        {
          name: 'Clients',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: color.secondaryBg,
            borderWidth: 4
          },
          label: {
            show: false,
            position: 'center',
            formatter(param) {
              // correct the percentage
              return param.name + '\n' + param.value + ' (' + param.percent + '%)';
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 13,
              fontWeight: '600', color: color.lightText, lineHeight: 18
            }
          },
          data: clients_overview ? [
            { value: clients_overview.paid, name: 'Paid Client', itemStyle: { color: color.blue } },
            { value: clients_overview.renew, name: 'Renew Client', itemStyle: { color: color.green } },
            { value: clients_overview.free, name: 'Free Trials', itemStyle: { color: color.red } },
          ]
            : [
              { value: 300, name: 'Paid Client', itemStyle: { color: color.blue } },
              { value: 200, name: 'Renew Client', itemStyle: { color: color.green } },
              { value: 600, name: 'Free Trials', itemStyle: { color: color.red } },
            ]
        }
      ]
    };

    chart.setOption(option);
    // chart2.setOption(pieOptions);

    // Clean up the chart when the component is unmounted
    return () => {
      chart.dispose();
      // chart2.dispose();
    };
  }, [color, business_overview, clients_overview]);

  const [bo, setBo] = useState('M')
  useEffect(() => {
    if (bo === "M") {
      fetchBusinessOverview()
    } else if (bo === "Y") {
      fetchBusinessOverviewYear()
    }
  }, [bo])

  // -------------------------------------Search Work-------------------------------------

  const [searchInput, setSearchInput] = useState("")

  const search = () => {
    navigate(AdminPath.SEARCH + '/' + searchInput)
  }


  return (
    <>
      <div className="myrow mb-4">
        <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
          <div>
            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
          </div>
          <div className='ms-2'>
            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
              <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
            </Ripples>
          </div>
        </div>
      </div>
      {/* ------------------------------------------- */}
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ic:round-phone-callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.MYDATA}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{totals2.total_callbacks ? numFormatter(totals2.total_callbacks) : 0}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.MYDATA}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=${today}&endDate=${today}&search=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Today Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.today_callbacks)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:call-transfer" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.MYDATA}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=${tommorow}&endDate=${tommorow}&search=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Tomorrow Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.tomorrow_callbacks)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:end-call" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.MYDATA}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=${'1900-01-01'}&endDate=${today}&search=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Pending Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.pending_callbacks)}</div>
            </Link>
          </div>
        </div>

      </div>
      {/* --------------------------------------------------Second Block For Totals----------------------------- */}
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="solar:money-bag-bold" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&type=&startDate=${startdate}&endDate=${endate}&search=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Bussines</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>₹ {totals.total_bussines ? numFormatter(totals.total_bussines) : 0}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-tie" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.ASSIGNEDLEADS}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Data</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.total_clients)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-online" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.LEADS}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Leads</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.callbacks)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ri:service-fill" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.NEWLEADS}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>New Data</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.followup)}</div>
            </Link>
          </div>
        </div>

      </div>
      {/* **********************Second Block Dashboard**************************** */}
      <div className="myrow justify_space_between mb-4">
        <div className="business_block1 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px' }}>Business Overview</h6>

          <div className="myrow">
            <div className="w-100">
              <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
              <div className='myrow' style={{ marginTop: '-20px', justifyContent: 'center' }}>
                <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                  <button onClick={() => setBo("M")} className={bo === "M" ? "primaryButton" : "secondaryButton"} style={{ marginRight: '1px' }}><Icon icon="mynaui:letter-m" fontSize={18} /></button>
                </Ripples>
                <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                  <button onClick={() => setBo("Y")} className={bo === "Y" ? "primaryButton" : "secondaryButton"} style={{ marginRight: '1px' }}><Icon icon="mynaui:letter-y" fontSize={18} /></button>
                </Ripples>
              </div>
            </div>
            {/* <div className="ww100">
              <div ref={chartRef2} style={{ width: '100%', height: '400px' }} />
            </div> */}
          </div>
        </div>

        <div className="business_block2 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '10px' }}>Top Performers</h6>
          <hr style={{ borderColor: color.borderColor }} />
          {performance.length > 0 ? <>
            {performance.map((f, count) => {
              return <PerformanceList data={f} key={count} />
            })}
          </> :
            <div style={{ color: color.red, fontSize: '14px' }}>No Payment for Now.</div>
          }


        </div>
      </div>


    </>
  )
}

export default Dashboard