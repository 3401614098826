import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useComplianceMsgStore = create((set) => ({
    complianceMsg: [],
    fetchComplianceMsg: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'research/view-compliance-message/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ complianceMsg: data });
    },
    createComplianceMsg: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/create-compliance-message/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    sendComplianceMsg: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/send-compliance-message/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateComplianceMsg: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'research/update-compliance-message/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteComplianceMsg: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'research/delete-compliance-message/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));