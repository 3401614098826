import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useNewsletterStore = create((set) => ({
    newsletter: [],
    fetchNewsLetter: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-newsletter/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ newsletter: data });
    },
    deleteNewsLetter: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-newsletter/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));