import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import moment from 'moment'

const PrList = (props) => {
    const { data, count } = props
    const {color} = useColorStore()
    return (
        <>
            <tr>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{count}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.system_issue_date ? data.system_issue_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.inward_date ? data.inward_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.quote ? data.quote : ''}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.prev_policy_number ? data.prev_policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.transaction_type ? data.transaction_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_code ? data.product_code : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_name ? data.product_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.insured_name ? data.insured_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.address ? data.address : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_number ? data.policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_start_date ? data.policy_start_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_end_date ? data.policy_end_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement ? data.endorsement : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_type ? data.endorsement_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_reason ? data.endorsement_reason : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_type ? data.policy_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.sum_assured ? data.sum_assured : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.net_premium ? data.net_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.gross_premium ? data.gross_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.payment_mode ? data.payment_mode : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.instrument_number ? data.instrument_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.bank_name ? data.bank_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.branch_name ? data.branch_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{moment(data.created_at).format('LLL')}</td>
                {/* <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td> */}
            </tr>
        </>
    )
}

export default PrList