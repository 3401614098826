import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const usePastPerformanceStore = create((set) => ({
    pastPerformance: [],
    fetchPastPerformance: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-past-performance/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ pastPerformance: data });
    },
    createPastPerformance: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/create-past-performance/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updatePastPerformance: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'web/update-past-performance/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deletePastPerformance: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-past-performance/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));