import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'
import { useUserAddressStore } from '../../../../../Store/UserStore/UserAddressStore'

const Addresses = (props) => {
  const { addToast } = useToasts()
  const { color, isLoading, setIsLoading, currentUser,permission } = props
  const { users, fetchUsers } = useUserStore()
  const { usersAddressP, usersAddressC, fetchUsersAddressP, fetchUsersAddressC, createUsersAddressP, createUsersAddressC } = useUserAddressStore()
  const [error, setError] = useState(false)
  const [error2, setError2] = useState(false)

  const [payload, setPayload] = useState({
    user: currentUser.id,
    add_type: "PERMANENT",
    address: usersAddressP.address,
    district: usersAddressP.district,
    state: usersAddressP.state,
    pincode: usersAddressP.pincode,
  })

  const [payload2, setPayload2] = useState({
    user: currentUser.id,
    add_type: "CORRESPONDENCE",
    address: usersAddressC.address,
    district: usersAddressC.district,
    state: usersAddressC.state,
    pincode: usersAddressC.pincode,
  })

  const handleSubmit = () => {
    if (payload.add_type.length === 0 || payload.address.length === 0 || payload.district.length === 0 || payload.state.length === 0 || payload.pincode.length === 0) {
      setError(true)
    } else {
      setError(false)
      createUsersAddressP(payload)
        .then(res => {
          fetchUsersAddressP(currentUser.id)
          setIsLoading(false)
          setPayload({
            ...payload,
            user: currentUser.id,
            add_type: "PERMANENT",
            address: payload.address,
            district: payload.district,
            state: payload.state,
            pincode: payload.pincode,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }
  const handleSubmit2 = () => {
    if (payload2.add_type.length === 0 || payload2.address.length === 0 || payload2.district.length === 0 || payload2.state.length === 0 || payload2.pincode.length === 0) {
      setError2(true)
    } else {
      setError2(false)
      createUsersAddressC(payload2)
        .then(res => {
          fetchUsersAddressC(currentUser.id)
          setIsLoading(false)
          setPayload2({
            ...payload2,
            user: currentUser.id,
            add_type: "CORRESPONDENCE",
            address: payload2.address,
            district: payload2.district,
            state: payload2.state,
            pincode: payload2.pincode,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  useEffect(() => {
    if (usersAddressP) {
      setPayload({
        ...payload,
        user: currentUser.id,
        add_type: "PERMANENT",
        address: usersAddressP.address,
        district: usersAddressP.district,
        state: usersAddressP.state,
        pincode: usersAddressP.pincode,
      })
    } else {
      setPayload({
        ...payload,
        user: currentUser.id,
        add_type: "PERMANENT",
        address: "",
        district: "",
        state: "",
        pincode: "",
      })
    }
  }, [usersAddressP])

  useEffect(() => {
    if (usersAddressC) {
      setPayload2({
        ...payload2,
        user: currentUser.id,
        add_type: "CORRESPONDENCE",
        address: usersAddressC.address,
        district: usersAddressC.district,
        state: usersAddressC.state,
        pincode: usersAddressC.pincode,
      })
    } else {
      setPayload2({
        ...payload2,
        user: currentUser.id,
        add_type: "CORRESPONDENCE",
        address: "",
        district: "",
        state: "",
        pincode: "",
      })
    }
  }, [usersAddressC])

  useEffect(() => {
    fetchUsersAddressC(currentUser.id)
  }, [])

  useEffect(() => {
    fetchUsersAddressP(currentUser.id)
  }, [])

  return (
    <>
      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          <div style={{ color: color.lightText, fontSize: '16px', fontWeight: 500, textTransform: 'uppercase' }}>Permanent Address</div>

          {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

          <div className='mb-2 mt-4'>
            <label className='modal_label' style={{ color: color.darkText }}>Address<span className="text-danger">*</span></label>
            <textarea placeholder='Enter address' rows={4} value={payload.address} disabled={!permission} onChange={(e) => setPayload({ ...payload, address: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
            </textarea>
          </div>


          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>District <span className="text-danger">*</span></label>
            <input placeholder='Enter district...' value={payload.district} disabled={!permission} onChange={(e) => setPayload({ ...payload, district: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>State <span className="text-danger">*</span></label>
            <input placeholder='Enter state...' value={payload.state} disabled={!permission} onChange={(e) => setPayload({ ...payload, state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Pincode <span className="text-danger">*</span></label>
            <input placeholder='Enter pincode...' value={payload.pincode} disabled={!permission} onChange={(e) => setPayload({ ...payload, pincode: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

        </div>

        {permission &&
          <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>

              : "Submit"}</button>
          </div>
        }
      </div>

      {/* -------------------------------------Correspondance Address--------------------------------------------- */}

      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          <div style={{ color: color.lightText, fontSize: '16px', fontWeight: 500, textTransform: 'uppercase' }}>CORRESPONDENCE Address</div>

          {error2 && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

          <div className='mb-2 mt-4'>
            <label className='modal_label' style={{ color: color.darkText }}>Address<span className="text-danger">*</span></label>
            <textarea placeholder='Enter address' rows={4} value={payload2.address} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, address: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
            </textarea>
          </div>


          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>District <span className="text-danger">*</span></label>
            <input placeholder='Enter district...' value={payload2.district} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, district: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>State <span className="text-danger">*</span></label>
            <input placeholder='Enter state...' value={payload2.state} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Pincode <span className="text-danger">*</span></label>
            <input placeholder='Enter pincode...' value={payload2.pincode} disabled={!permission} onChange={(e) => setPayload2({ ...payload2, pincode: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

        </div>

        <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
          <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit2}>{isLoading ?
            <>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
              Please wait...
            </>

            : "Submit"}</button>
        </div>
      </div>
    </>
  )
}

export default Addresses