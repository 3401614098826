import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useBlogStore = create((set) => ({
    blogs: [],
    fetchBlog: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-blogs/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ blogs: data });
    },
    createBlog: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/create-blogs/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateBlog: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'web/update-blog/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteBlog: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-blog/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));