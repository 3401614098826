import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useIpAddressStore = create((set) => ({
    ip: [],
    fetchIpAddress: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-ip/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ ip: data });
    },
    createIpAddress: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-ip/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },   
    deleteIpAddress: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'auth/delete-ip/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    }
}));