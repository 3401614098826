import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { AdminPath } from './AdminPath'
import AdminDashboard from './../AdminDashboard/AdminDashboard';
import Category from '../Category/Category';
import Dashboard from './../Dashboard/Dashboard';
import SubCategory from '../SubCategory/SubCategory';
import Product from '../Product/Product';
import PdfFiles from '../Spaces/PdfFiles/PdfFiles';
import ProductBrochure from '../Spaces/ProductBrochure/ProductBrochure';
import QrScanner from '../Spaces/QrScanner/QrScanner';
import DynamicEmail from '../Spaces/DynamicEmail/DynamicEmail';
import IpAddress from '../Spaces/IpAddress/IpAddress';
import Users from '../Accounts/Users/Users';
import UserDetails from '../Accounts/Users/UserDetails/UserDetails';
import Email from '../../Email/Email';
import Inbox from '../../Email/Inbox';
import AllLeads from '../Leads/AllLeads/AllLeads';
import LeadsDetails from '../Leads/LeadsDetails/LeadsDetails';
import Services from '../Leads/Services/Services';
import AssignedLeads from '../Leads/AssignedLeads/AssignedLeads';
import ScrollToTop from './ScrollToTop';
import Compliance from '../Compliance/Compliance';
import UserPermissions from '../UserPermissions/UserPermissions';
import Settings from '../Settings/Settings';
import WebEnquiry from '../Website/WebEnquiry/WebEnquiry';
import NewsLetter from '../Website/NewsLetter/NewsLetter';
import Demo from '../Website/Demo/Demo';
import PaymentUser from '../Website/PaymentUser/PaymentUser';
import Complaint from '../Website/Complaint/Complaint';
import ComplaintData from '../Website/ComplaintData/ComplaintData';
import Blogs from '../Website/Blogs/Blogs';
import Recommendation from '../../Research/Recommendation/Recommendation';
import FollowUp from '../Leads/FollowUp/FollowUp';
import NewLeads from '../Leads/AssignedLeads/NewLeads';
import Leads from './../Leads/Leads/Leads';
import FieldLeads from '../Leads/FieldLeads/FieldLeads';
import Task from '../Task/Task';
import SourceLeads from '../../Operations/Source Leads/SourceLeads';
import Mis from '../../Operations/Mis/Mis';
import Dash from '../../Dash/Dash';
import Pr from '../../Operations/Pr/Pr';
import RenewData from '../../Service/Data/RenewData';
import Mydata from './../Leads/Mydata/Mydata';
import SearchMis from '../../Operations/Mis/SearchMis';
import Claims from '../../Service/Claims/Claims';

export const AdminRoutes = () => {
    return (
        <>
        <ScrollToTop />
            <Routes>
                <Route path={AdminPath.DASHBOARD} element={<Dash />} />
                <Route path={AdminPath.CATEGORY} element={<Category />} />
                <Route path={AdminPath.SUBCATEGORY} element={<SubCategory />} />
                <Route path={AdminPath.PRODUCT} element={<Product />} />
                <Route path={AdminPath.PDFFILES} element={<PdfFiles />} />
                <Route path={AdminPath.PRODUCTBROCHURE} element={<ProductBrochure />} />
                <Route path={AdminPath.QRSCANNER} element={<QrScanner />} />
                <Route path={AdminPath.DYNAMICEMAIL} element={<DynamicEmail />} />
                <Route path={AdminPath.IPADDRESS} element={<IpAddress />} />
                <Route path={AdminPath.USERS} element={<Users />} />
                <Route path={AdminPath.USERS + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.PROFILE + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.EMAIL} element={<Email />} />
                <Route path={AdminPath.LEADS} element={<Leads />} />
                <Route path={AdminPath.CLAIMS} element={<Claims />} />
                <Route path={AdminPath.TASKS} element={<Task />} />
                <Route path={AdminPath.FIELDLEADS} element={<FieldLeads />} />
                <Route path={AdminPath.ALLLEADS} element={<AllLeads />} />
                <Route path={AdminPath.ASSIGNEDLEADS} element={<AssignedLeads />} />
                <Route path={AdminPath.NEWLEADS} element={<NewLeads />} />
                <Route path={AdminPath.MYDATA} element={<Mydata />} />
                <Route path={AdminPath.SEARCH+'/:id'} element={<SearchMis />} />
                <Route path={AdminPath.FOLLOWUPLEADS+ "/:fid"} element={<FollowUp />} />
                <Route path={AdminPath.ALLLEADS+ "/:userId"} element={<LeadsDetails />} />
                <Route path={AdminPath.LEADSSERVICES} element={<Services />} />
                <Route path={AdminPath.SOURCELEADS} element={<SourceLeads />} />
                <Route path={AdminPath.ALLINVOICES} element={<Compliance />} />
                <Route path={AdminPath.USERPERMISSION} element={<UserPermissions />} />
                <Route path={AdminPath.SETTINGS} element={<Settings />} />

                <Route path={AdminPath.MIS} element={<Mis />} />
                <Route path={AdminPath.PR} element={<Pr />} />
                <Route path={AdminPath.RENEWDATA} element={<RenewData />} />
            </Routes>
        </>
    )
}
